import React, { useEffect, useState } from 'react'
import useGetPublicData from '../hooks/useGetPublicData'
import usePost from '../hooks/usePost'
import { Alert } from 'antd'
import AlertComp from '../components/comps/alert'
import { Context } from '../AppContext'

export default function Contact() {
    const { getPublicData } = useGetPublicData()
    const { postData } = usePost()
    const [data, setdata] = useState()
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState('')
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    async function fetchData(params) {
        await getPublicData("app?includes=address,email,phone,", (d) => setdata(d))
    }
    async function sendMsg(params) {
        await postData("contact", { email, subject, name, message })
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div class="bg-default-2 pt-16 pb-12 pt-lg-22 pb-lg-27">
            <div class="container">
                <div class="row justify-content-center mt-14">
                    <div class="col-xxl-6 col-xl-7 col-lg-8">
                        <h2 class="font-size-9 text-center mb-11">Contact Us</h2>
                        <AlertComp />
                        <div class="bg-white px-4 pt-9 pb-7 shadow-8 rounded-4">
                            <form onSubmit={(e) => { e.preventDefault(); sendMsg() }}>
                                <div class="row">
                                    <div class="col-12 mb-7">
                                        <label
                                            for=""
                                            class="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                                        >Your Name</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            required
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                        />
                                    </div>
                                    <div class="col-lg-6 mb-7">
                                        <label
                                            for=""
                                            class="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                                        >E-mail</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </div>
                                    <div class="col-lg-6 mb-7">
                                        <label
                                            for=""
                                            class="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                                        >Subject</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            required
                                            onChange={(e) => setSubject(e.target.value)}
                                            value={subject}
                                        />
                                    </div>
                                    <div class="col-lg-12 mb-7">
                                        <label
                                            for="message"
                                            class="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                                        >Message</label
                                        >
                                        <textarea
                                            placeholder="Type your message"
                                            class="form-control h-px-144"
                                            required
                                            onChange={(e) => setMessage(e.target.value)}
                                            value={message}
                                        ></textarea>
                                    </div>
                                    <div class="col-lg-12 pt-4">
                                        <button type='submit' class="btn btn-primary text-uppercase w-100 h-px-48">
                                            Send Now
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div class="mt-8">
                                <h3 class="font-size-4">Contact Information</h3>
                                <div class="media mb-2">
                                    <div class="mr-6">
                                        <i class="fas fa-map-marker-alt mt-2"></i>
                                    </div>
                                    <p class="font-size-4 mb-0">
                                        {data?.address}
                                    </p>
                                </div>
                                <div class="media mb-2">
                                    <div class="mr-6">
                                        <i class="fas fa-phone-alt mt-2"></i>
                                    </div>
                                    <p class="font-size-4 mb-0">{data?.phone}</p>
                                </div>
                                <div class="media mb-2">
                                    <div class="mr-6">
                                        <i class="fas fa-envelope mt-2"></i>
                                    </div>
                                    <p class="font-size-4 mb-0">{data?.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
