import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, Space, List } from 'antd';
import { WhatsAppOutlined, MailOutlined, SendOutlined, CheckCircleOutlined, DollarOutlined, FacebookOutlined } from '@ant-design/icons';
import useGetPublicData from '../../../../hooks/useGetPublicData';

const { Text, Link, Paragraph } = Typography;

const LicenseInfoModal = ({ isModalVisible, company, handleOk = () => null, handleOpen = () => null }) => {
    const { getPublicData } = useGetPublicData()
    const [data, setdata] = useState()

    async function fetchData(params) {
        await getPublicData("app?includes=telegram,email,phone,license_fee,facebook", (d) => setdata(d))
    }
    useEffect(() => {
        fetchData()
    }, [])



    return (
        <>
            <Modal
                title={<div className='px-5 text-center'>Important Information</div>}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleOk}
                footer={
                    <div className="d-flex justify-content-between px-5">
                        <Button key="submit" type="default" onClick={handleOpen} style={{ borderColor: '#008a5b' }}>
                            See example
                        </Button>
                        <Button key="submit" type="primary" onClick={() => window.location.href = `/user/payment?amount=${data?.license_fee}&reason=License`} style={{ backgroundColor: '#008a5b', borderColor: '#008a5b' }}>
                            Get License
                        </Button>
                    </div>
                }
            >
                <Typography className='mt-10'>
                    <Paragraph>
                        <CheckCircleOutlined style={{ color: '#52c41a' }} className='mr-4' />
                        <Text strong> {company} does not directly issue driving licenses.</Text>
                        {" "}However, we can assist you
                        in starting the application process by taking the theory test
                        with the <strong>Driver and Vehicle Standards Agency</strong>. On completion,
                        the result will be sent to you via email then you can upload.
                    </Paragraph>

                    <Paragraph>
                        <CheckCircleOutlined style={{ color: '#52c41a' }} className='mr-4' />
                        Once you arrive in the UK, you can begin the process of taking the physical driving test.
                    </Paragraph>
                    <br />
                    <Text strong>Payment Details:</Text>
                    <List
                        size="small"
                        dataSource={[
                            { label: 'Reason:', value: 'License' },
                            { label: 'Amount:', value: `${data?.license_fee} USD` },
                        ]}
                        renderItem={(item) => (
                            <List.Item>
                                <DollarOutlined style={{ color: '#52c41a' }} /> <Text strong>{item.label}</Text> {item.value}
                            </List.Item>
                        )}
                    />

                    <br />
                    <Paragraph>
                        To proceed with payment, click here: <Link
                            href={`/user/payment?amount=${data?.license_fee}&reason=License`}
                            target="_blank" rel="noopener noreferrer">Payment Link</Link>.
                    </Paragraph>

                    <Paragraph>
                        For any personal question or guidance, you can reach us by clicking the following:
                    </Paragraph>

                    <Space className='d-flex justify-content-around my-10' size="small">
                        <Link href={"https://wa.me/" + data?.phone} target="_blank">
                            <WhatsAppOutlined style={{ fontSize: '24px', color: '#25D366' }} /> WhatsApp
                        </Link>
                        <Link href={"https://m.me/" + data?.facebook} target="_blank">
                            <FacebookOutlined style={{ fontSize: '24px', color: '#0088cc' }} /> Facebook
                        </Link>
                        <Link href={"mailto:" + data?.email} target="_blank">
                            <MailOutlined style={{ fontSize: '24px', color: '#f5222d' }} /> Email
                        </Link>
                    </Space>
                    <Paragraph className='text-center'>
                        We are humans. We guide! We listen!
                    </Paragraph>
                </Typography>
            </Modal>
        </>
    );
};

export default LicenseInfoModal;
