import React from 'react'
import ApplyJob from '../../../components/comps/applyJob';

export default function Jobcard({ data, setActive = () => null, active }) {
    const { logo, title, company, location, postedDate, type, _id, index } = data
    return (
        <div
            className={`${active === index ? "active" : ""} mb-8 p-0 w-100 nav-link`}
        >
            <div
                onClick={(e) => { e.preventDefault(); setActive(index) }}
                class="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 hover-border-green"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="media align-items-center">
                            <div class="square-72 d-block mr-8">
                                <img
                                    src={logo}
                                    alt=""
                                    className='img-fluid'
                                />
                            </div>
                            <div>
                                <h3 class="mb-0 font-size-6 heading-default-color">
                                    {title}
                                </h3>
                                <span
                                    class="font-size-3 text-default-color line-height-2 d-block"
                                >{company}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row pt-8">
                    <div class="col-12">
                        <ul
                            class="d-flex list-unstyled mr-n3 flex-wrap mr-n8"
                        >
                            <li class="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                <span class="mr-4" style={{ marginTop: -2 }}
                                ><img
                                        src="/image/svg/icon-loaction-pin-black.svg"
                                        alt=""
                                    /></span>
                                <span class="font-weight-semibold">{location}</span>
                            </li>
                            <li class="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                <span class="mr-4" style={{ marginTop: -2 }}
                                ><img src="/image/svg/icon-suitecase.svg" alt=""
                                    /></span>
                                <span class="font-weight-semibold">{type}</span>
                            </li>
                            <li class="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                <span class="mr-4" style={{ marginTop: -2 }}
                                ><img src="/image/svg/icon-clock.svg" alt=""
                                    /></span>
                                <span class="font-weight-semibold">{postedDate}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
