import React, { useEffect, useState } from 'react';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import usePostPrivate from '../../../hooks/usePostPrivate';
import AlertComp from '../../../components/comps/alert';
import { truncateEmail } from '../../../util';
import NoDataCard from '../../../components/comps/nodata';
import CosUpload from './comp/uploadcos';
import { Button, Modal } from 'antd';
import AppointmentFormModal from './comp/appointment';


const Clients = () => {
    const { getData } = useGetPrivateData()
    const [isInfoModalVisible, setIsInfoModalVisible] = useState();
    const [data, setdata] = useState()
    const [aptData, setAptData] = useState()
    const [open, setOpen] = useState(false)
    const [id, setId] = useState()
    const [active, setActive] = useState()
    async function getAppointmentData(params) {
        await getData("admin/appointment", (d) => setAptData(d))
    }
    useEffect(() => {
        getAppointmentData()
    }, [])
    async function fetchJobs(params) {
        await getData("admin/client", (d) => { setdata(d); })
    }
    const { postData } = usePostPrivate()
    async function bill(item) {
        await postData("admin/bill", { ...item }, async () => await fetchJobs())
    }
    async function performAction(id, action) {
        await postData("offer/action", { applicationId: id, action }, async () => await fetchJobs())
    }

    useEffect(() => {
        fetchJobs()
    }, [])

    return (
        <>
            <div class="col-12 col-xl-10 col-lg-10 mt-3 ">
                <div class="mb-5 px-5">
                    <h6 className='my-3 text-center'>Billing </h6>
                    <AlertComp />
                    <div class="row justify-content-center vh-100 py-3 px-3">
                        {data && data?.length > 0 ? <div class="table-responsive w-100">
                            <table class="table table-striped">
                                <tbody>
                                    {data.map((item, index) =>
                                        <div className='position-relative'>
                                            <tr class="border d-flex align-items-center border-color-2 justify-content-between">
                                                <th scope="row" class="pl-6 border-0 py-7 pr-0">
                                                    <span
                                                        class=" media align-items-center"
                                                    >
                                                        <h4
                                                            class="font-size-4 mb-0 font-weight-semibold text-black-2"
                                                        >
                                                            {truncateEmail(item.email)}
                                                        </h4>
                                                    </span>
                                                    <p style={{ fontSize: 13 }}>Stage: {item.stage}</p>
                                                </th>
                                                <td class="table-y-middle py-7 pr-10">
                                                    <div class="dropdown d-flex justify-content-between">
                                                        <button onClick={() => setActive(active === index ? undefined : index)} class="btn btn-primary font-size-3 font-weight-bold text-uppercase dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Action
                                                        </button>
                                                    </div>

                                                </td>
                                            </tr>
                                            {active === index && <ul style={{ zIndex: 900 }} class="dropdown-menu float-right position-absolute show">
                                                <li class="dropdown-item" >
                                                    <button onClick={() => { performAction(item.applicationId, "RL") }}
                                                        disabled={!item.hasUserPostedLicense || item.stage !== 1 || item.hasAdminSentLetter}
                                                        className='btn btn-unstyled w-100'>
                                                        Reject License
                                                    </button>
                                                </li>
                                                <li class="dropdown-item" >
                                                    <button onClick={() => { performAction(item.applicationId, "SL") }}
                                                        disabled={!item.hasUserPaidForLicense || item.stage !== 1 || item.hasAdminSentLicense}
                                                        className='btn btn-unstyled w-100'>
                                                        Have you sent License?
                                                    </button>
                                                </li>
                                                <li class="dropdown-item" >
                                                    <button onClick={() => { setId(item.applicationId); setOpen(true) }}
                                                        disabled={!item.hasAdminSentLicense || !item.hasUserPostedLicense || item.stage !== 1 || item.hasAdminSentLetter}
                                                        className='btn btn-unstyled w-100'>
                                                        Send Letter(Confirm License)
                                                    </button>
                                                </li>
                                                <li class="dropdown-item" >
                                                    <button
                                                        disabled={(!item.hasAdminSentLetter || item.stage !== 1
                                                            || !item.hasUserPostedLetter) ||
                                                            (item.hasUserPostedCert ||
                                                                (item.ECStatus === "declined" || item.ECStatus === "accepted"))}
                                                        onClick={() => bill(item)}
                                                        className='btn btn-unstyled w-100'>
                                                        Bill(Cert)
                                                    </button>
                                                </li>
                                                <li class="dropdown-item">
                                                    <button disabled={item.stage !== 2 || !item.hasUserPostedCert || item.isCertConfirmed || item.hasUserPaidForCert} onClick={() => { performAction(item.applicationId, "RP") }} className='btn btn-unstyled w-100'>
                                                        Reject Cert
                                                    </button>
                                                </li>
                                                <li class="dropdown-item">
                                                    <button disabled={item.stage !== 2 || !item.hasUserPaidForCert || item.hasAdminSentCert}
                                                        onClick={() => { performAction(item.applicationId, "SC") }} className='btn btn-unstyled w-100'>
                                                        Have you sent Cert?
                                                    </button>
                                                </li>
                                                <li class="dropdown-item" >
                                                    <button disabled={!item.hasAdminSentCert || item.stage !== 2 || !item.hasUserPostedCert || item.isCertConfirmed}
                                                        onClick={() => { performAction(item.applicationId, "AP") }} className='btn btn-unstyled w-100'>
                                                        Accept Cert</button>
                                                </li>

                                                <li class="dropdown-item" >
                                                    <button disabled={item.stage !== 2 || !item.isCertConfirmed || (item.cos)} onClick={() => { setIsInfoModalVisible(item.applicationId) }} className='btn btn-unstyled w-100'>
                                                        Upload COS
                                                    </button>
                                                </li>
                                                <li class="dropdown-item"><button disabled={
                                                    !item.isCoSPosted}
                                                    onClick={() => bill(item)} className='btn btn-unstyled w-100'>Bill (CoS)</button></li>
                                            </ul>}
                                        </div>

                                    )}
                                </tbody>
                            </table>
                        </div> : data && <NoDataCard isBorder={false} />}
                    </div>
                </div>
            </div>
            <Modal
                open={isInfoModalVisible !== undefined}
                onCancel={() => setIsInfoModalVisible()}
                footer={() => null}
            >
                <CosUpload id={isInfoModalVisible} />
            </Modal>
            <AppointmentFormModal open={open} id={id} callBack={async () => { setId(); await getAppointmentData(); setOpen(false) }} />
        </>
    );
};

export default Clients;
