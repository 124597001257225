import React, { useState } from 'react'
import AlertComp from '../../components/comps/alert'
import usePost from '../../hooks/usePost'
import axios from 'axios'
import { Context } from '../../AppContext'

export default function Register() {
    const [firstName, setFirstName] = useState("")
    const [password, setpassword] = useState("")
    const [lastName, setLastName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [email, setemail] = useState("")
    const { postData, loading } = usePost()
    const { country } = Context()
    async function reg(params) {
        const result = await axios.get("https://ipapi.co/json")
        const { country_name, country_code } = result.data
        const data = { country_name, country_code, email, password, name: `${firstName} ${lastName} ${middleName || ""}`, country_name: country }
        await postData("user", data)
    }
    return (
        <div className=" row h-100 px-8 pt-12 justify-content-center flex-column align-items-center">
            <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 my-9 my-lg-15 px-6  pt-10 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <div class="mt-5 ">
                    <h5 className='text-primary text-center'>Create an account</h5>
                    <form className='mt-10' onSubmit={(e) => { e.preventDefault(); reg() }}>
                        <AlertComp />
                        <div class="form-group ">
                            <label
                                for="name"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >First Name
                            </label>
                            <input
                                type="name"
                                class="form-control"
                                placeholder="Enter name"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                required
                                aria-label="name"
                                name='name'
                            />
                        </div>
                        <div class="form-group ">
                            <label
                                for="name"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >Last Name
                            </label>
                            <input
                                type="name"
                                class="form-control"
                                placeholder="Enter last name"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                required
                                aria-label="name"
                                name='name'
                            />
                        </div>
                        <div class="form-group ">
                            <label
                                for="name"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >Middle Name
                            </label>
                            <input
                                type="name"
                                class="form-control"
                                placeholder="Enter middle name"
                                onChange={(e) => setMiddleName(e.target.value)}
                                value={middleName}
                                aria-label="name"
                                name='name'
                            />
                        </div>
                        <div class="form-group">
                            <label
                                for="email"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >E-mail
                            </label>
                            <input
                                type="email"
                                class="form-control"
                                placeholder="example@gmail.com"
                                onChange={(e) => setemail(e.target.value)}
                                value={email}
                                required
                                aria-label="email"
                                name='email'
                            />
                        </div>
                        <div class="form-group">
                            <div class="d-flex flex-wrap justify-content-between"><label
                                for="password"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >Password</label>
                            </div>

                            <div class="position-relative">
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter password"
                                    onChange={(e) => setpassword(e.target.value)}
                                    value={password}
                                    required
                                />
                            </div>
                        </div>
                        <p className='text-left ml-0 p-0' style={{ fontSize: 14 }}>By signing up, you are acknowledging our <a
                            href="/privacy"
                            class="text-primary"
                        >privacy notice </a>.</p>
                        <div class="form-group mb-8">
                            <button
                                class="btn btn-primary btn-medium w-100 rounded-5"
                            >
                                {loading ? "Signing up..." : "CREATE ACCOUNT"}
                            </button>
                        </div>
                        <p style={{ fontSize: 14 }} class="heading-default-color text-center">
                            Already have an account?{" "}
                            <a href="/auth/login" class="text-primary"> Sign in</a>
                        </p>
                    </form>
                </div>
            </div></div>
    )
}
