import React, { useEffect, useState } from 'react';
import usePostPrivate from '../../../hooks/usePostPrivate';
import { Context } from '../../../AppContext';

import UploadDocuments from './uploaddocs';

const JobAccept = ({ token, data, rejectOffer = () => null }) => {
    const { postData } = usePostPrivate()
    const { user, } = Context()
    const handleSubmit = async () => {
        const data = { token, email: user?.email, offer: "accept" }
        await postData("offer/update", data, async () => window.location.reload())

    };


    return (

        <UploadDocuments rejectOffer={rejectOffer} acceptOffer={handleSubmit}
            token={token}
            current={data?.uploadStatus}
            uploads={data?.upload} />
    );
};

export default JobAccept;
