import React from 'react'
import { Context } from '../../AppContext';

export default function ApplyJob({ id }) {
    const {  user, } = Context()
    const { applied } = user
    const isApplied = applied && applied?.includes(id)
    async function apply() {
        window.location.href = isApplied ? "/user/applied" : "/job/apply/" + id
    }
    return (
        <a
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); apply() }}
            class="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5"
            href="#"
        >{isApplied ? "View Details" : "Apply"} </a>
    )
}
