import React, { useEffect, useState } from 'react'
import useGetPrivateData from '../../hooks/useGetPrivateData'
import NoDataCard from '../../components/comps/nodata'
import Preloader from '../../components/comps/preloader'

export default function Applied() {
    const [data, setdata] = useState()
    const { getData, loading } = useGetPrivateData()
    async function getApplied(params) {
        await getData("jobs?applied=" + "applied", (d) => setdata(d.reverse()))
    }
    useEffect(() => {
        getApplied()
    }, [])

    return (
        <div class="col-12 col-xl-10 col-lg-10 mt-14">
            <Preloader open={loading || !data} />
            <div class="mb-5">
                <h4 class="font-size-7 mb-9">Applied Jobs</h4>
                <div class="row justify-content-center py-3">
                    {data && data?.length > 0 ? data?.map(item =>
                        <a href={'/job/' + item._id} key={item._id} class="nav-link nav-tab w-auto mb-9">
                            <div
                                class=" pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 hover-border-green"
                            >
                                <div class="media mx-8 align-items-center">
                                    <div class="square-52 bg-indigo mr-8 rounded">
                                        <a href="#"><img src={item.logo} alt="" className='img-fluid' /></a>
                                    </div>
                                    <div>
                                        <a href="#" class="font-size-3 text-default-color line-height-2"
                                        >{item.company}</a
                                        >
                                        <h3 class="font-size-6 mb-0">
                                            <a class="heading-default-color font-weight-semibold" href="#"
                                            >{item.title}</a
                                            >
                                        </h3>
                                    </div>
                                </div>
                                <div class="d-flex pt-17">
                                    <ul class="list-unstyled mb-1 d-flex flex-wrap">
                                        <li>
                                            <a
                                                href="#"
                                                class="bg-regent-opacity-15 text-denim font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4"
                                            >
                                                <i class="icon icon-pin-3 mr-2 font-weight-bold"></i> {item.location}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                class="bg-regent-opacity-15 text-orange font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4"
                                            >
                                                <i class="fa fa-briefcase mr-2 font-weight-bold"></i>
                                                {item.type}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </a>) : data && <NoDataCard />}
                </div>
            </div>
        </div>
    )
}
