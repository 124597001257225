import React from 'react'
import CryptoPaymentForm from '../comp/cryptoform'
import AlertComp from '../../../components/comps/alert'

export default function Crypto() {
    return (
        <> <div class="col-12 col-xl-10 col-lg-10 mt-14 ">
            <div class="mb-5">
                <h4 class="font-size-7 mb-9">Crypto Payment Method </h4>
                <div class="border-0 card py-10 px-2">
                    <AlertComp />
                    <CryptoPaymentForm />
                </div>
            </div>
        </div></>
    )
}
