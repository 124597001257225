import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, Space, List } from 'antd';
import { WhatsAppOutlined, MailOutlined, SendOutlined, CheckCircleOutlined, DollarOutlined, FacebookOutlined } from '@ant-design/icons';
import useGetPublicData from '../../../../hooks/useGetPublicData';

const { Text, Link, Paragraph } = Typography;

const ProofInfoModal = ({ isModalVisible = true, company, handleOk = () => null }) => {
    const { getPublicData } = useGetPublicData()
    const [data, setdata] = useState()

    async function fetchData(params) {
        await getPublicData("app?includes=telegram,email,phone,certifcate_fee,facebook", (d) => setdata(d))
        
    }
    useEffect(() => {
        fetchData()
    }, [])
    function getCert(params) {
        window.location.href = `/user/payment?amount=${data?.certifcate_fee}&reason=Proof of English Proficiency`
        handleOk()
    }

    return (
        <>
            <Modal
                title={<div className='px-5 text-center'>Important Information</div>}
                open={isModalVisible}
                onOk={getCert}
                onCancel={handleOk}
                footer={[
                    <Button key="submit" type="primary" onClick={getCert} style={{ backgroundColor: '#008a5b', borderColor: '#008a5b' }}>
                        Get Cert
                    </Button>
                ]}
            >
                <Typography className='mt-10'>
                    <Paragraph>
                        <CheckCircleOutlined style={{ color: '#52c41a' }} className='mr-4' />
                        <Text strong> {company} does not directly issue Proof of English Proficiency.</Text> However, we can assist foreign candidates in starting the application process with registering and getting an IELTs certificate.
                    </Paragraph>

                    <Paragraph>
                        <CheckCircleOutlined style={{ color: '#52c41a' }} className='mr-4' />
                        Once you arrive in the UK, you must physically take the test.
                    </Paragraph>
                    <br />
                    <Text strong>Payment Details:</Text>
                    <List
                        size="small"
                        dataSource={[
                            { label: 'Reason:', value: 'Proof of English Proficiency' },
                            { label: 'Amount:', value: `${data?.certifcate_fee} USD` },
                        ]}
                        renderItem={(item) => (
                            <List.Item>
                                <DollarOutlined style={{ color: '#52c41a' }} /> <Text strong>{item.label}</Text> {item.value}
                            </List.Item>
                        )}
                    />

                    <br />
                    <Paragraph>
                        To proceed with payment, click here: <Link href={`/user/payment?amount=${data?.certifcate_fee}&reason=Proof of English Proficiency`} target="_blank" rel="noopener noreferrer">Payment Link</Link>.
                        <Paragraph> <strong>Upon confirmation, your document will be sent to you via email.
                            Then you can proceed to upload it.</strong></Paragraph >
                    </Paragraph>

                    <Paragraph>
                        For any personal question or guidance, you can reach us by clicking the following:
                    </Paragraph>

                    <Space className='d-flex justify-content-around my-10' size="small">
                        <Link href={"https://wa.me/" + data?.phone} target="_blank">
                            <WhatsAppOutlined style={{ fontSize: '24px', color: '#25D366' }} /> WhatsApp
                        </Link>
                        <Link href={"https://m.me/" + data?.facebook} target="_blank">
                            <FacebookOutlined style={{ fontSize: '24px', color: '#0088cc' }} /> Facebook
                        </Link>
                        <Link href={"mailto:" + data?.email} target="_blank">
                            <MailOutlined style={{ fontSize: '24px', color: '#f5222d' }} /> Email
                        </Link>
                    </Space>
                    <Paragraph className='text-center'>
                        We are humans. We guide! We listen!
                    </Paragraph>
                </Typography>
            </Modal>
        </>
    );
};

export default ProofInfoModal;
