import React, { useEffect, useState } from 'react';
import { Upload, Form, Button, List, Checkbox } from 'antd';
import { PlusOutlined, CheckCircleTwoTone, CloseCircleTwoTone, } from '@ant-design/icons';
import usePostFormPrivate from '../../../hooks/usePostForm';
import { Context } from '../../../AppContext';

const documentTitles = [
    { label: "International Passport", name: "passport" },
    { label: "Passport Photograph", name: "passportPhoto" },
    { label: "Academic Qualification", name: "qualification" },
    { label: "Valid UK Driving License", name: "license" }
];

const UploadDocuments = ({ token, current, uploads, acceptOffer = () => null, rejectOffer = () => null }) => {
    const [currentStep, setCurrentStep] = useState();
    const [fileList, setFileList] = useState([]);
    const [uploadedDocs, setUploadedDocs] = useState([]);
    const [noDocChecked, setNoDocChecked] = useState(false);
    const { postFormData } = usePostFormPrivate()
    const { user } = Context()
    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleNoDocChange = (e) => {
        setNoDocChecked(e.target.checked);
        if (e.target.checked) {
            setFileList([]); // Clear file list if "No document" is selected
        }
    };



    
    async function uploadDocument(data, condition) {
        const formData = new FormData();
        data.map(file => formData.append(condition, file.originFileObj))
        formData.append('email', user?.email)
        formData.append('token', token);
        formData.append('condition', condition);
        await postFormData("upload/documents", formData, async () => { window.location.reload() })
    }
    useEffect(() => {
        setCurrentStep(current)
    }, [current])


    const isLastStep = currentStep >= documentTitles.length;
    useEffect(() => {
        setUploadedDocs(documentTitles?.map(item => {
            const findName = uploads?.find(i => i.title === item.name)
            return {
                name: item.label,
                isUrl: findName ? true : false
            }
        }))
    }, [isLastStep])
    return (
        currentStep !== undefined && <div>
            {!isLastStep ? (
                <Form layout="vertical">
                    <Form.Item label={"Upload " + documentTitles[currentStep].label}>
                        <Upload
                            fileList={fileList}
                            onChange={handleUploadChange}
                            beforeUpload={() => false} // Prevent automatic upload
                            disabled={noDocChecked} // Disable upload if "No document" is checked
                            listType="picture-card" // Shows image preview and icon only
                            maxCount={4}
                            accept="image/*"
                        >
                            {<Button icon={<PlusOutlined />}></Button>}
                        </Upload>
                    </Form.Item>
                    <Checkbox onChange={handleNoDocChange} checked={noDocChecked}>
                        No, I don’t have this document
                    </Checkbox>
                    <div className="my-10">
                        <Button
                            onClick={() => uploadDocument(fileList, documentTitles[currentStep].name)}
                            disabled={fileList.length === 0 && !noDocChecked}
                            style={{ marginTop: '10px', backgroundColor: "#00b074", color: "#fff", borderColor: "#00b074" }}
                        >
                            {noDocChecked ? 'Next' : 'Upload'}
                        </Button>
                        <Button type="default" onClick={rejectOffer} style={{ marginLeft: '10px' }}>
                            Reject Offer
                        </Button>
                    </div>
                </Form>
            ) : (
                <div>
                    <h3>Documents Status</h3>
                    <List
                        dataSource={uploadedDocs}
                        renderItem={(doc) => (
                            <List.Item>
                                {doc.name}{' '}
                                {doc.isUrl ? (
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                ) : (
                                    <CloseCircleTwoTone twoToneColor="#ff4d4f" />
                                )}
                            </List.Item>
                        )}
                    />
                    <div className="">
                        <Button
                            onClick={acceptOffer}
                            style={{ backgroundColor: "#00b074", color: "#fff", borderColor: "#00b074", marginTop: '10px' }}
                        >
                            Accept Offer
                        </Button>
                        <Button type="default" onClick={rejectOffer} style={{ marginLeft: '10px' }}>
                            Reject Offer
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UploadDocuments;
