import React from 'react'
import UserDetails from './comp/userItem'
import AlertComp from '../../../components/comps/alert'

export default function AllUsers() {
    return (
        <div class="col-12 col-xl-10 col-lg-10 mt-3 ">
            <div class="mb-5 px-5">
                <h4 class="font-size-7 mb-9">All Users</h4>
                <AlertComp />
                <div class="row justify-content-center card py-3 px-3">
                    <UserDetails />
                </div>
            </div>
        </div>
    )
}
