import React, { useEffect, useState } from 'react';
import { List, Card, Button, Modal, Image } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import NoDataCard from '../../../components/comps/nodata';
import AlertComp from '../../../components/comps/alert';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import usePostPrivate from '../../../hooks/usePostPrivate';

const ImageDisplayList = ({ onDelete = () => null }) => {

    const [imageData, setImageData] = useState([])
    const { getData } = useGetPrivateData()
    const { postData } = usePostPrivate()
    async function fetchImages(params) {
        await getData("upload", (d) => setImageData(d))
    }
    async function deleteImages(token, id) {
        await postData("upload", { token, id }, async () => await fetchImages())
    }
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleItemClick = (image) => {
        setSelectedImage(image);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedImage(null);
    };
    useEffect(() => {
        fetchImages()
    }, [])

    return (
        <>
            <div class="col-12 col-xl-10 col-lg-10 mt-3 ">
                <div class="mb-5 px-5">
                    <h6 className='my-3 text-center'>Images </h6>
                    <AlertComp />
                    <div class="row justify-content-center card py-3 px-3">
                        <Card>
                            <List
                                itemLayout="horizontal"
                                className='w-100'
                                dataSource={imageData}
                                bordered={false}
                                renderItem={(image) => (
                                    <List.Item
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleItemClick(image)}
                                        actions={[
                                            <Button
                                                type="primary"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent click event from triggering image modal
                                                    deleteImages(image.token, image.id);
                                                }}
                                            >
                                                Delete
                                            </Button>,
                                        ]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Image width={100} src={image.url} />}
                                            title={image.title}
                                            description={`${image.email}`}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </div>
                </div>
            </div>


            <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleModalClose}
            >
                {selectedImage && (
                    <Image
                        src={selectedImage.url}
                        alt={selectedImage.title}
                        style={{ width: '100%' }}
                    />
                )}
            </Modal>
        </>
    );
};

export default ImageDisplayList;
