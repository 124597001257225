import { Navigate, Route, BrowserRouter as Router, Routes, useSearchParams } from "react-router-dom";
import Header from "./components/shared/header";
import Login from "./pages/auth/login";
import Auth from './pages/auth/auth';
import Register from "./pages/auth/register";
import { Context } from "./AppContext";
import BackDrop from "./components/comps/backdrop";
import ForgotPassword from "./pages/auth/forgotpassword";
import ResetPassword from "./pages/auth/resetpassword";
import Verifyemail from "./pages/auth/verifyemail";
import Job from "./pages/job/job";
import Joblist from "./pages/job/joblist";
import Jobdetail from "./pages/job/joddetail";
import Error404 from "./pages/error/error404";
import useGetPrivateData from './hooks/useGetPrivateData';
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import Jobapply from "./pages/job/jobapply";
import User from "./pages/user/user";
import Applied from "./pages/user/applied";
import Saved from "./pages/user/saved";
import Application from "./pages/user/application";
import Settings from './pages/user/settings';
import Interview from "./pages/interview/interview";
import Payment from "./pages/user/payment/paymentMethod";
import PaymentOutlet from "./pages/user/payment/payment";
import Crypto from "./pages/user/payment/crypto";
import History from "./pages/user/history";
import Admin from "./pages/user/admin/admin";
import Editjobs from "./pages/user/admin/editjobs";
import CandidateApplications from "./pages/user/admin/application";
import AllUsers from './pages/user/admin/users';
import InterviewResult from "./pages/user/admin/interview";
import Clients from "./pages/user/admin/clients";
import Adminpayment from "./pages/user/admin/payment";
import Adminsettings from "./pages/user/admin/settings";
import Preloader from "./components/comps/preloader";
import Error500 from "./pages/error/error500";
import Contact from "./pages/contact";
import Message from "./pages/user/admin/message";
import Footer from "./components/shared/footer";
import useGetPublicData from "./hooks/useGetPublicData";
import axios from "axios";
import AdminAppointment from "./pages/user/admin/adminAppointment";
import Offersingle from "./pages/offer/offersingle";
import { stringToArray } from "./util";
import ImageDisplayList from "./pages/user/admin/imagedisplay";

function App() {
  const [app, setapp] = useState()
  const [continent, setContinent] = useState()
  const [started, setStarted] = useState(false)
  const [blacklist, setBlacklist] = useState([])
  const { contextHolder, globalLoading, setUser, user, is500Error, country, setCountry } = Context()
  const { getData, loading } = useGetPrivateData()
  const { getPublicData, loading: apploading } = useGetPublicData()
  async function fetchUser(params) {
    await getData("user", (d) => { setUser(d); setCountry(d.countryName) })
  }
  async function fetchApp(params) {
    setStarted(true)
    await getPublicData("app?includes=logo,name,favico,blacklist", (d) => {
      setapp({ logo: d.logo, company: d.name })
      setBlacklist(stringToArray(d.blacklist))
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = d.favico;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = d.name + " | " + document.title
    })

  }

  async function fetchCountry(params) {
    const result = await axios.get("https://ipapi.co/json")
    const { country_name, continent_code } = result.data
    setCountry(country_name)
    setContinent(continent_code)
    Cookies.set("country", country_name, { expires: 30 })
    Cookies.set("continent", continent_code, { expires: 30 })
  }
  useEffect(() => {
    fetchApp()
    const isCookie = Cookies.get("user")
    const cookieCountry = Cookies.get("country")
    const cookieContinent = Cookies.get("continent")
    if (isCookie) {
      fetchUser()
    } else {
      setUser({})
      if (cookieCountry && cookieContinent) {
        setCountry(cookieCountry)
        setContinent(cookieContinent)
      } else {
        fetchCountry()
      }
    }
  }, [])

  return (
    (blacklist.includes(continent) || blacklist.includes(country)) ? <Error500 /> : <div className="site-wrapper overflow-hidden">
      <Preloader open={loading || apploading || (!app && !started) || (!country)} />
      {(is500Error || (!app && started)) && !loading && !apploading ? <Error500 /> : app && started &&
        <Router>
          {contextHolder}
          <Header country={country} logo={app?.logo} user={user} />
          <Routes>
            <Route path="/auth/" element={<Auth />} >
              <Route index element={<Navigate to="/auth/login" />} />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/register" element={<Register />} />
              <Route path="/auth/forgot-password" element={<ForgotPassword />} />
              <Route path="/auth/reset-password" element={<ResetPassword />} />
              <Route path="/auth/resend-email" element={<Verifyemail />} />
            </Route>
            <Route path="/" element={<Job />} >
              <Route path="/" element={<Joblist />} />
              <Route path="/job/:id" element={<Jobdetail />} />
              <Route path="/job/apply/:id" element={<Jobapply />} />
            </Route>
            {user?.name && <Route path="/user/" element={<User />} >
              <Route path="/user/" element={<Application />} />
              <Route path="/user/admin" element={<Admin />} >
                <Route path="/user/admin" element={<Editjobs />} />
                <Route path="/user/admin/application" element={<CandidateApplications />} />
                <Route path="/user/admin/users" element={<AllUsers />} />
                <Route path="/user/admin/interview" element={<InterviewResult />} />
                <Route path="/user/admin/billing" element={<Clients />} />
                <Route path="/user/admin/payment" element={<Adminpayment />} />
                <Route path="/user/admin/message" element={<Message />} />
                <Route path="/user/admin/settings" element={<Adminsettings />} />
                <Route path="/user/admin/images" element={<ImageDisplayList />} />
                <Route path="/user/admin/appointment" element={<AdminAppointment />} />
              </Route>
              <Route path="/user/applied" element={<Applied />} />
              <Route path="/user/saved" element={<Saved />} />
              {user?.ready && <>
                <Route path="/user/history" element={<History />} />
                <Route path="/user/payment/" element={<PaymentOutlet />} >
                  <Route path="/user/payment/" element={<Payment />} />
                  <Route path="/user/payment/crypto" element={<Crypto />} />
                </Route>
              </>}
              <Route path="/user/settings" element={<Settings />} />
            </Route>}
            <Route path="/offer/:token" element={<Offersingle company={app?.company} />} />
            <Route path="/interview/:id" element={<Interview />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Error500 />} />
            {(!globalLoading || !is500Error) && <Route path="*" element={<Error404 />} />}
          </Routes>
          {!globalLoading && <Footer logo={app?.logo} company={app?.company} />}
          <BackDrop open={globalLoading} />
        </Router>}

    </div>
  );
}

export default App;
