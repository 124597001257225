import React, { useEffect, useState } from 'react';
import { Collapse, Card, List, Typography } from 'antd';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import Preloader from '../../../components/comps/preloader';
import NoDataCard from '../../../components/comps/nodata';

const { Panel } = Collapse;
const { Title, Text } = Typography;

const CryptoPaymentHistory = () => {
  const [data, setdata] = useState()
  const { getData, loading } = useGetPrivateData()

  async function fetchData(params) {
    await getData("payment", (d) => setdata(d))
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Preloader open={loading || !data} />
      {data && data?.length > 0 ? <Collapse size='large' className='my-4  border-0 p-0 w-100' accordion>
        {data?.map((payment, index) => (
          <Panel
            header={
              <div className='d-flex justify-content-between '>
                <Text className='mx-5' strong>{payment.amount} {payment.cryptoName}</Text>
              </div>
            }
            key={index}
          >
            <Card bordered={false}>
              <List>
                <List.Item>
                  <Text strong>Transaction ID:</Text> {payment.transId}
                </List.Item>
                <List.Item>
                  <Text strong>Date:</Text> {payment.dateString}
                </List.Item>
                <List.Item>
                  <Text strong>Reason:</Text> {payment.reason}
                </List.Item>
                <List.Item>
                  <Text strong>Amount:</Text> {payment.amount} {payment.cryptoName}
                </List.Item>
                <List.Item>
                  <Text strong>Status:</Text> {payment.status}
                </List.Item>
              </List>
            </Card>
          </Panel>
        ))}
      </Collapse> : data && <NoDataCard isBorder={false} />}
    </>
  );
};

export default CryptoPaymentHistory;
