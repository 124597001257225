import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useGetPublicData from '../../hooks/useGetPublicData'
import Error404 from '../error/error404'
import ApplyJob from '../../components/comps/applyJob'
import SaveJob from '../../components/comps/saveJob'
import AlertComp from '../../components/comps/alert'

export default function Jobdetail() {
    const { id } = useParams()
    const [data, setdata] = useState({})
    const { getPublicData, loading } = useGetPublicData()
    async function getData(params) {
        await getPublicData("job/" + id, (d) => setdata(d))
    }
    useEffect(() => {
        getData()
    }, [])
    function replaceBrTags(text) {
        return text.replace(/<br\s*\/?>/g, '<br/>');
    }
    return (
        data && Object.values(data)?.length === 0 && !loading ? <Error404 /> : <div class="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-10 col-lg-11 mt-4 ml-xxl-32 ml-xl-15 dark-mode-texts">
                        <div class="mb-9">
                            <a class="d-flex align-items-center ml-4" href="/">
                                <i
                                    class="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"
                                >
                                </i><span class="text-uppercase font-size-3 font-weight-bold text-gray"
                                >Back to Jobs</span></a
                            >
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
                        <div class="bg-white rounded-4 border border-mercury shadow-9">
                            {Object.values(data)?.length > 0 && 
                            <>
                                <div
                                    class="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts"
                                >
                                    <AlertComp />
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="media align-items-center">
                                                <div class="square-72 d-block mr-8">
                                                    <img
                                                        src={data.logo}
                                                        alt=""
                                                        className='img-fluid'
                                                    />
                                                </div>
                                                <div>
                                                    <h3 class="font-size-6 mb-0">{data.title}</h3>
                                                    <span class="font-size-3 text-gray line-height-2"
                                                    >{data.company}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row pt-9">
                                        <div class="col-12">
                                            <div class="card-btn-group d-flex gap-2">
                                                <ApplyJob id={data._id} />
                                                <SaveJob id={data._id} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts"
                                >
                                    <div class="row mb-5">
                                        <div class="col-md-12">
                                            <div class="media justify-content-md-start mb-6">
                                                <div class="image mr-5">
                                                    <img src="/image/svg/icon-location.svg" alt="" />
                                                </div>
                                                <p class="font-size-5 text-gray mb-0">
                                                    {data.location}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="media justify-content-md-start mb-md-0 mb-6">
                                                <div class="image mr-5">
                                                    <img src="/image/svg/icon-briefcase.svg" alt="" />
                                                </div>
                                                <p
                                                    class="font-weight-semibold font-size-5 text-black-2 mb-0"
                                                >
                                                    {data.type}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            {data.industry && <div class="mb-lg-0 mb-10">
                                                <span class="font-size-4 d-block mb-4 text-gray"
                                                >Industry</span
                                                >
                                                <h6
                                                    class="font-size-5 text-black-2 font-weight-semibold mb-9"
                                                >
                                                    {data.industry}
                                                </h6>
                                            </div>}
                                            {data.job && <div class="mb-lg-0 mb-10">
                                                <span class="font-size-4 d-block mb-4 text-gray"
                                                >Job Function</span
                                                >
                                                <h6
                                                    class="font-size-5 text-black-2 font-weight-semibold mb-9"
                                                >
                                                    {data.job}
                                                </h6>
                                            </div>}
                                        </div>
                                        <div class="col-md-6 mb-lg-0 mb-8">
                                            {data.level && <div class="">
                                                <span class="font-size-4 d-block mb-4 text-gray"
                                                >Career Level</span
                                                >
                                                <h6
                                                    class="font-size-5 text-black-2 font-weight-semibold mb-9"
                                                >
                                                    {data.level}
                                                </h6>
                                            </div>}
                                        </div>
                                        <div class="col-12">
                                            <div class="">
                                                <span class="font-size-4 d-block mb-4 text-gray"
                                                >Posted Time</span
                                                >
                                                <h6
                                                    class="font-size-5 text-black-2 font-weight-semibold mb-0"
                                                >
                                                    {data.postedDate}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-8 pl-sm-9 pl-6 pb-10 light-mode-texts">
                                    <div class="row">
                                        <div class="col-xxl-12 col-xl-9 pr-xxl-18 pr-xl-0 pr-11">
                                            <div dangerouslySetInnerHTML={{ __html: replaceBrTags(data.detail) }} class="">
                                            </div>
                                            <div className="mt-5"><ApplyJob id={data._id} /></div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
