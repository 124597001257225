import React from 'react'
import Cookies from 'js-cookie';
import usePostPrivate from '../../hooks/usePostPrivate';
import { Context } from '../../AppContext';

export default function SaveJob({ id }) {
    const { setFeedback, openNotificationWithIcon, user, setUser } = Context()
    const { saved,name } = user
    const isSaved = saved && saved?.includes(id)
    const { postData } = usePostPrivate()
    async function save() {
        setFeedback()
        if (!name) {
            setFeedback({ msg: "You must be logged in to save offer. Go to <a href='/auth/login'> LOGIN PAGE </a> to continue.", type: "error" })
            openNotificationWithIcon("error", "You must be logged in to save offer. Go to <a href='/auth/login'> LOGIN PAGE </a> to continue.",)
            return;
        }
        await postData((isSaved ? "unsave?id=" : "save?id=") + id, {}, () => setUser(isSaved ? (saved.filter(i => i !== id))
            : ({ ...user, saved: [...saved, id] })))
    }

    return (
        <a
            class="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5"
            href="#"
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); save() }}
        >
            <i
                class="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"
            ></i>
            {isSaved ? "Unsave" : "Save"}</a>
    )
}
