import { LinearProgress } from '@mui/material'
import React from 'react'
import { Context } from '../../AppContext'

export default function ProgressComp() {
    const { globalLoading } = Context()
    return (
        globalLoading ? <LinearProgress className='bg-primary mt-2' /> : <></>
    )
}
