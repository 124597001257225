import React from 'react'

export default function Error500() {
    return (
        <div
            class="404-page bg-default min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18"
        >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7 px-lg-9">
                        <div
                            class="card-404 text-center"
                            data-aos="zoom-in"
                            data-aos-duration="800"
                            data-aos-once="true"
                        >
                            <img src="/image/error500.jpg" alt="" class="w-100 px-9" />
                            <div class="404-texts pt-14">
                                <p
                                    class="card-text font-size-4 px-xxl-28 px-xs-10 px-sm-13 px-lg-13 px-md-28 px-xl-22 px-0 mb-11"
                                >
                                    Internal Server Error
                                </p>
                                <a
                                    href="/"
                                    class="btn btn-green btn-h-60 mb-10 text-white rounded-5 w-180 mx-auto text-uppercase"
                                >Back to home</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
