import React, { useEffect, useState } from 'react'
import useGetPrivateData from '../../../hooks/useGetPrivateData'
import { Collapse, Card, List, Typography } from 'antd';
import Preloader from '../../../components/comps/preloader';
import NoDataCard from '../../../components/comps/nodata';
import { truncateEmail } from '../../../util';
import AlertComp from '../../../components/comps/alert';
import AppointmentFormModal from './comp/appointment';


const { Panel } = Collapse;
const { Text } = Typography;

export default function AdminAppointment() {
    const { getData } = useGetPrivateData()
    const [data, setData] = useState()
    const [open, setOpen] = useState(false)
    const [id, setId] = useState()
    async function getAppointmentData(params) {
        await getData("admin/appointment", (d) => setData(d))
    }
    useEffect(() => {
        getAppointmentData()
    }, [])
    return (
        <>
            <div class="col-12 col-xl-10 col-lg-10 mt-3 ">
                <div class="mb-5 px-5">
                    <h4 class="font-size-7 mb-9">Send Appointment Letter</h4>
                    <AlertComp />
                    <div class="row justify-content-center card py-3 px-3">
                        <Preloader open={!data} />
                        {data && data?.length > 0 ? <Collapse size='large' className='my-4  border-0 p-0 w-100' accordion>
                            {data.map((user, index) => (
                                <Panel
                                    key={index}
                                    header={
                                        <div className='d-flex justify-content-between '>
                                            <Text strong>{truncateEmail(user.email)}</Text>
                                            <Text className='mx-5' strong>{user.country}</Text>
                                        </div>
                                    }
                                >
                                    <Card bordered={false}>
                                        <List>
                                            {Object.keys(user).map(item => <List.Item>
                                                <Text strong>{item}:</Text> {user[item]}
                                            </List.Item>)}
                                        </List>
                                        <button onClick={() => { setId(user.applicationId); setOpen(true) }} className='btn btn-primary ml-5 mt-10'>Send Appointment Letter</button>
                                    </Card>
                                </Panel>
                            ))}
                        </Collapse> : data && <NoDataCard isBorder={false} />}
                    </div>
                </div>
            </div>
            <AppointmentFormModal open={open} id={id} callBack={async () => { setId(); await getAppointmentData(); setOpen(false) }} />
        </>
    )
}
