import React, { useEffect, useState } from 'react'
import AlertComp from '../../../components/comps/alert'
import { Collapse, Button, Tag, Modal, Input } from 'antd';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import { truncateEmail } from '../../../util';
import usePostPrivate from '../../../hooks/usePostPrivate';
import NoDataCard from '../../../components/comps/nodata';

const { Panel } = Collapse;
const { TextArea } = Input;

export default function Message() {
    const [messages, setmessages] = useState()
    const { getData } = useGetPrivateData()
    const { postData } = usePostPrivate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [replyText, setReplyText] = useState('');

    const showModal = (message) => {
        setCurrentMessage(message);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        await postData("message", { id: currentMessage._id, message: replyText }, async () => await fetchData())
        setIsModalVisible(false);
        setReplyText('');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setReplyText('');
    };
    async function fetchData(params) {
        await getData("message", (d) => setmessages(d))
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div class="col-12 col-xl-10 col-lg-10 mt-4 ">
            <div class="mb-5 px-5">
                <h4 class="font-size-7 mb-9">Messages</h4>
                <AlertComp />
                <div class="row justify-content-center card border-0 py-3 px-3">
                    {messages && messages?.length > 0 ? <Collapse accordion>
                        {messages.map((message, index) => (
                            <Panel
                                key={index}
                                header={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{truncateEmail(message.email)}</span>
                                        <Tag color={message.replied ? 'green' : 'red'}>
                                            {message.replied ? 'Replied' : 'Not Replied'}
                                        </Tag>
                                    </div>
                                }
                            >
                                <div>
                                    <p style={{ fontSize: 12 }}>{message.message}</p>
                                    <Button className='btn btn-primary mt-7' onClick={() => showModal(message)}>Reply</Button>
                                </div>
                            </Panel>
                        ))}
                    </Collapse> : messages && <NoDataCard isBorder={false} />}
                </div>
            </div>
            <Modal
                title={`Reply to ${currentMessage?.email}`}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <TextArea
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    rows={4}
                    placeholder="Type your reply here..."
                />
            </Modal>
        </div>
    )
}
