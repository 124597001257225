import React, { useState } from "react";
import { axiosPrivate, } from "../api";
import { Context } from "../AppContext";
import { domain } from "../util";

const useGetPrivateData = () => {
    const [loading, setLoading] = useState(false);
    const { setIs500Error, setGLobalLoading } = Context()
    async function getData(url, successFunction) {
        setLoading(true);
        setGLobalLoading(true)
        try {
            const response = await axiosPrivate.get(domain + url);
            successFunction(response.data);
            setIs500Error(false)
        } catch (error) {
            if (error.response?.status === 500) {
                setIs500Error(true)
            }
        } finally {
            setLoading(false)
            setGLobalLoading(false)
        }
    }
    return { getData, loading };
};

export default useGetPrivateData;
