import React from 'react';
import { Card, Typography, Space } from 'antd';
import { CheckCircleFilled, SmileOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const DocumentReviewCard = () => {
    return (
        <div className="w-100 d-flex justify-content-center"><Card
            style={{ width: 400, margin: '50px auto', textAlign: 'center', borderRadius: 10, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
        >
            <Space direction="vertical" size="middle">
                <CheckCircleFilled style={{ fontSize: '48px', color: '#52c41a' }} />
                <Title level={3}>Your document(s) have been received</Title>
                <Text type="secondary">
                    Your documents are under review. We will notify you once the review is completed.
                </Text>
            </Space>
        </Card></div>
    );
};

export default DocumentReviewCard;
