import React, { useState } from "react";
import { Context } from "../AppContext";
import { axiosPuplic } from '../api';
import { domain } from "../util";

const usePost = () => {
    const [loading, setLoading] = useState(false);
    const { setFeedback, setGLobalLoading,openNotificationWithIcon } = Context()
    async function postData(url, info, successFunction = () => null) {
        setFeedback()
        try {
            setLoading(true);
            setGLobalLoading(true)
            const response = await axiosPuplic.post(domain + url, info);
            response.data.msg && setFeedback({ msg: response.data.msg, type: "success" })
            response.data.msg && openNotificationWithIcon("success", response.data.msg)
            await successFunction(response.data.data)
        } catch (error) {
            error.response?.data && setFeedback({ msg: error.response?.data, type: "error" })
            error.response?.data && openNotificationWithIcon("error", error.response?.data,)
        } finally {
            setLoading(false);
            setGLobalLoading(false)
        }
    }
    return { postData, loading };
};

export default usePost;
