import React, { useState } from 'react';
import { Form, Input, Button, Radio, message } from 'antd';
import { Context } from '../../../AppContext';
import { useSearchParams } from 'react-router-dom';

const PaymentForm = () => {
    const { setFeedback, openNotificationWithIcon } = Context()
    const amount = useSearchParams()[0].get("amount")
    const reason = useSearchParams()[0].get("reason")

    const onFinish = (values) => {
        setFeedback()
        if (values.paymentMethod === 'card') {
            setFeedback({ msg: "We're experiencing issues with card payment. Kindly choose another payment method.", type: "error" })
            openNotificationWithIcon("error", "We're experiencing issues with card payment. Kindly choose another payment method.",)
        } else {
            window.location.href = "/user/payment/crypto?amount=" + values.amount + "&reason=" + values.description
        }
    };


    return (
        <Form
            name="paymentForm"
            layout="vertical"
            onFinish={onFinish}
            size='large'
            initialValues={{ paymentMethod: 'crypto' }}
        >
            <Form.Item
                label="What are you paying for?"
                name="description"
                rules={[{ required: true, message: 'Please enter what you are paying for' }]}

            >
                <Input defaultValue={reason} />
            </Form.Item>

            <Form.Item
                label="Enter Amount in USD"
                name="amount"
                rules={[{ required: true, message: 'Please enter the amount' }]}
            >
                <Input defaultValue={amount} type="number" />
            </Form.Item>

            <Form.Item
                label="Select Payment Method"
                name="paymentMethod"
                rules={[{ required: true, message: 'Please select a payment method' }]}
            >
                <Radio.Group>
                    <Radio value="card">Card Payment</Radio>
                    <Radio value="crypto">Crypto</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item>
                <Button className='btn-primary btn' htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PaymentForm;
