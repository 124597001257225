import React from 'react'
import { adminMenu, menu } from '../lib'
import { useLocation } from 'react-router-dom'

export default function Adminsidebar() {
    const location = useLocation().pathname

    return (
        <>  <div class="dashboard-sidebar-wrapper pt-11" id="sidebar">
            <ul class=" my-15 list-unstyled dashboard-layout-sidebar">
                {adminMenu.map(item => <li key={item.name} class={location === item.link ? "active" : ""}>
                    <a
                        href={item.link}
                        class="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
                    >{item.icon}{item.name}</a>
                </li>)}
            </ul>
        </div > <a class="sidebar-mobile-button" data-toggle="collapse" href="#sidebar" role="button" aria-expanded="false" aria-controls="sidebar">
                <i class="icon icon-sidebar-2"></i>
            </a></>
    )
}
