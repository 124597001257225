import React from 'react'
import ApplyJob from '../../../components/comps/applyJob';
import SaveJob from '../../../components/comps/saveJob';
import AlertComp from '../../../components/comps/alert';
import { Context } from '../../../AppContext';

export default function Detail({ data }) {
    const { logo, title, company, location, postedDate, type, _id, industry, job, detail, level } = data
    const { screenWidth } = Context()
    function replaceBrTags(text) {
        return detail.replace(/<br\s*\/?>/g, '<br/>');
    }
    return (
        <div class="col-12 col-xxl-4 col-xl-5 position-static">
            <div class="tab-content">
                <div
                    class="tab-pane fade show active"
                    id="tab-pane-1"
                    role="tabpanel"
                    aria-labelledby="tab-nav-1"
                >
                    <div
                        class="bg-white rounded-4  pos-abs-xl ml-xl-8 h-1413 overflow-y-scroll mt-9 mt-xl-0"
                    >
                        <div className="px-5"><AlertComp /></div>
                        <div
                            class="pt-9 px-0 pb-8 border-bottom border-width-1 border-default-color light-mode-texts"
                        >
                            <div class="row">
                                <div class="col-12">
                                    <div class="media align-items-center">
                                        <div class="square-72 ml-5 d-block mr-8">
                                            <img
                                                src={logo}
                                                alt=""
                                                className='img-fluid'
                                            />
                                        </div>
                                        <div>
                                            <h3 class="font-size-6 mb-0">{title}</h3>
                                            <span class="font-size-3 text-gray line-height-2"
                                            >{company}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-9">
                                <div class="col-12">
                                    <div class="card-btn-group d-flex gap-2 pl-5">
                                        <ApplyJob id={_id} />
                                        <SaveJob id={_id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts"
                        >
                            <div class="row mb-5">
                                <div class="col-md-12">
                                    <div class="media justify-content-md-start mb-6">
                                        <div class="image mr-5">
                                            <img src="/image/svg/icon-location.svg" alt="" />
                                        </div>
                                        <p class="font-size-5 text-gray mb-0">
                                            {location}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="media justify-content-md-start mb-md-0 mb-6">
                                        <div class="image mr-5">
                                            <img src="/image/svg/icon-briefcase.svg" alt="" />
                                        </div>
                                        <p
                                            class="font-weight-semibold font-size-5 text-black-2 mb-0"
                                        >
                                            {type}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    {industry && <div class="mb-lg-0 mb-10">
                                        <span class="font-size-4 d-block mb-4 text-gray"
                                        >Industry</span
                                        >
                                        <h6
                                            class="font-size-5 text-black-2 font-weight-semibold mb-9"
                                        >
                                            {industry}
                                        </h6>
                                    </div>}
                                    {job && <div class="mb-lg-0 mb-10">
                                        <span class="font-size-4 d-block mb-4 text-gray"
                                        >Job Function</span
                                        >
                                        <h6
                                            class="font-size-5 text-black-2 font-weight-semibold mb-9"
                                        >
                                            {job}
                                        </h6>
                                    </div>}
                                </div>
                                <div class="col-md-6 mb-lg-0 mb-8">
                                    {level && <div class="">
                                        <span class="font-size-4 d-block mb-4 text-gray"
                                        >Career Level</span
                                        >
                                        <h6
                                            class="font-size-5 text-black-2 font-weight-semibold mb-9"
                                        >
                                            {level}
                                        </h6>
                                    </div>}
                                </div>
                                <div class="col-12">
                                    <div class="">
                                        <span class="font-size-4 d-block mb-4 text-gray"
                                        >Posted Time</span
                                        >
                                        <h6
                                            class="font-size-5 text-black-2 font-weight-semibold mb-0"
                                        >
                                            {postedDate}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt-8 pl-sm-9 pl-6 pb-10 light-mode-texts">
                            <div class="row">
                                <div class="col-xxl-12 col-xl-9 pr-xxl-18 pr-xl-0 pr-11">
                                    <div dangerouslySetInnerHTML={{ __html: replaceBrTags() }} class="">
                                    </div>
                                    {screenWidth >= 1200 && <ApplyJob id={_id} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
