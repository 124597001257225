import React, { useEffect, useState } from 'react';
import { Form, Input, Upload, Button, Radio, Typography, Alert, Row, Col } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import usePostFormPrivate from '../../../hooks/usePostForm';
import { Context } from '../../../AppContext';
import AlertComp from '../../../components/comps/alert';

import usePostPrivate from '../../../hooks/usePostPrivate';
import ProofInfoModal from './comps/proofmodal';
const { Text, Link } = Typography;

const Proof = ({ company, data }) => {
    const [hasUKLicense, setHasUKLicense] = useState(true);
    const [showLicenseInfo, setShowLicenseInfo] = useState(false);
    const [proof, setProof] = useState([]);
    const { token } = useParams()
    const { postFormData } = usePostFormPrivate()
    const { postData } = usePostPrivate()
    const { user } = Context()

    const handleLicenseChange = (e) => {
        setHasUKLicense(e.target.value === 'yes');
    };

    async function uploadCert(params) {
        const formData = new FormData();
        proof.map(file => formData.append('cert', file.originFileObj))
        formData.append('email', user?.email)
        formData.append('token', token);
        await postFormData("offer/update/cert", formData, async () => { window.location.reload() })
    }


    async function rejectOffer(params) {
        await postData("offer/reject?token=" + token, {}, () => window.location.reload())
    }
    return (

        <>
            <Form
                layout="vertical"
                style={{ margin: '0 10' }}
                className='px-5 w-100 py-10'
            >
                <div className="my-5"><AlertComp /></div>
                {/* English cert Upload Section */}
                <Form.Item label="Upload an English Language Cert. Eg IELTS,TOEFL">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="proof" valuePropName="fileList" getValueFromEvent={(e) => e.fileList} noStyle>
                                <Upload maxCount={4} fileList={proof} onChange={(e) => { setProof(e.fileList) }} listType="picture-card" beforeUpload={()=>false} accept="image/*">
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                {/* No License Option */}
                <Form.Item label="Have you uploaded a valid English Language Cert?">
                    <Radio.Group onChange={handleLicenseChange} defaultValue="yes">
                        <Radio value="yes">Yes! I have</Radio>
                        <Radio className='mt-3' value="no">No, I don't have a valid English Language Cert</Radio>
                    </Radio.Group>
                </Form.Item>

                {!hasUKLicense && (
                    <>
                        <Form.Item>
                            <Link href="#" className='text-primary' onClick={() => setShowLicenseInfo(true)}>
                                Click here to see the process of acquiring Proof of English Proficiency through {company}.
                            </Link>
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <button onClick={() => hasUKLicense && proof.length > 0  ? uploadCert() : setShowLicenseInfo(true)}
                        disabled={hasUKLicense && proof.length === 0} className='btn btn-primary' type="submit" style={{ backgroundColor: '#008a5b', borderColor: '#008a5b', marginRight: '8px' }}>
                        {hasUKLicense && proof.length > 0 ? "Upload Cert" : "Get Cert"}
                    </button>
                    <button onClick={rejectOffer} disabled={data?.offer === "reject"} className='btn btn-danger'>
                        {data?.offer === "reject" ? "Offer Rejected" : "Reject Offer"}
                    </button>
                </Form.Item>

            </Form>
            <ProofInfoModal isModalVisible={showLicenseInfo} company={company} handleOk={() => setShowLicenseInfo(false)} />
        </>
    );
};

export default Proof;
