import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, DatePicker, Button, Typography, } from 'antd';
import usePostPrivate from '../../../../hooks/usePostPrivate';
import useGetPrivateData from '../../../../hooks/useGetPrivateData';
import moment from 'moment';
const { Title, Text } = Typography;
const AppointmentFormModal = ({ open, callBack = () => null, id, fetch = () => null }) => {
    const [data, setdata] = useState({})
    const { postData } = usePostPrivate()
    const { getData } = useGetPrivateData()
    async function fetchData(params) {
        await getData("admin/original?id=" + id, (d) => setdata(d))
    }
    // Handle form submission
    const handleOk = async (values) => {
        const { annualSalary, companyName, jobTitle, startDate } = values
        const data = {
            date: moment(new Date()).format(`DD-MMM-YYYY`), id, salary: Number(annualSalary).toLocaleString(), company: companyName, title: jobTitle, startDate: moment(startDate.toLocaleString()).format('DD-MMM-YYYY')
        }
        await postData('appointment', { ...data }, async () => await fetch())
        callBack()
    };
    useEffect(() => {
        id && fetchData()
    }, [id])

    return (
        <div>
            <Modal
                title="Appointment Form"
                open={open}
                onCancel={callBack}
                footer={null} // Hide default footer
            >
                <div className='my-5 d-flex flex-column'>
                    <Text>Company name: <Text strong>{data?.company}</Text></Text>
                    <Text>Job Title: <Text strong>{data?.title}</Text></Text>
                    <div className='mt-5' dangerouslySetInnerHTML={{ __html: data?.detail }} style={{ fontSize: 14 }} />
                </div>
                <Form
                    layout="vertical"
                    onFinish={handleOk}
                >
                    <Form.Item
                        name="companyName"
                        label="Company Name"
                        rules={[{ required: true, message: 'Please input the company name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="jobTitle"
                        label="Job Title"
                        rules={[{ required: true, message: 'Please input the job title!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="startDate"
                        label="Start Date"
                        rules={[{ required: true, message: 'Please select the start date!' }]}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        name="annualSalary"
                        label="Annual Salary"
                        rules={[{ required: true, message: 'Please input the annual salary!' }]}
                    >
                        <Input type="number" prefix="£" />
                    </Form.Item>

                    <Form.Item>
                        <Button className='btn btn-primary' htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AppointmentFormModal;
