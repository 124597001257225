import React, { useState } from "react";
import { axiosPuplic } from "../api";
import { Context } from "../AppContext";

const useGetPublicData = () => {
    const [loading, setLoading] = useState(false);
    const { setIs500Error, setGLobalLoading } = Context()
    async function getPublicData(url, successFunction) {
        setLoading(true);
        setGLobalLoading(true)
        try {
            const response = await axiosPuplic.get(url);
            successFunction(response.data);
            setIs500Error(false)
        } catch (error) {
            if (error.response?.status === 500) {
                setIs500Error(true)
            }
        } finally {
            setLoading(false)
            setGLobalLoading(false)
        }
    }
    return { getPublicData, loading };
};

export default useGetPublicData;
