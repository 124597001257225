import React, { useEffect, useState } from 'react';
import { Modal, Card, Input, Button, Form, message } from 'antd';
import usePostPrivate from '../../../hooks/usePostPrivate';

const CryptoPaymentModal = ({ open = true, callBack = () => null, amount, reason, cryptoName }) => {
    const [form] = Form.useForm();
    const { postData } = usePostPrivate()

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        callBack()
    };
    useEffect(() => {
        const mod = document.getElementsByClassName("ant-modal-content")
        if (mod && open) {
            mod[0]?.classList.add("px-0")
        }
    }, [open])


    const handleSubmit = async (values) => {
        await postData("payment", { amount, reason, transId: values.cryptoPaymentId, cryptoName }, () => { window.location.href = "/user/history" })
        callBack()
    };

    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <div className='px-5'><Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                        <Button key="submit" className='btn-primary ml-4' onClick={handleOk}>
                            Submit
                        </Button></div>
                ]}
            >
                <Card className='border-0 px-0' title="Enter Crypto Payment ID">
                    <p style={{ fontSize: 13 }}>Please enter or paste the crypto transaction ID in the input field below:</p>
                    <Form form={form} size='large' onFinish={handleSubmit}>
                        <Form.Item
                            name="cryptoPaymentId"
                            rules={[{ required: true, message: 'Please enter the crypto transaction ID' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
        </div>
    );
};

export default CryptoPaymentModal;
