import React, { useState } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { LogOut } from '../../util';

const UserMenu = ({ name }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleButtonClick = () => {
        setIsClicked(!isClicked);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const menu = (
        <Menu>
            <Menu.Item key="1" icon={<UserOutlined />}>
                <a href='/user'>Profile</a>
            </Menu.Item>
            <Menu.Item key="2" icon={<SettingOutlined />}>

                <a href='/user/settings'>Settings</a>
            </Menu.Item>
            <Menu.Item onClick={LogOut} key="3" icon={<LogoutOutlined />}>
                Sign Out
            </Menu.Item>
        </Menu>
    );

    const buttonStyle = {
        backgroundColor: isClicked || isHovered ? '#fff' : '#00b074',
        color: isClicked || isHovered ? '#00b074' : '#fff',
        borderColor: '#00b074'
    };

    return (
        <Dropdown overlay={menu} placement="bottomLeft" onClick={handleButtonClick}>
            <Button
                style={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {name} <i style={{ fontSize: 12 }} class="fa-solid fa-chevron-down"></i>
            </Button>
        </Dropdown>
    );
};

export default UserMenu;
