import React, { useEffect, useState } from 'react'
import useGetPrivateData from '../../hooks/useGetPrivateData'
import { Collapse } from 'antd'
import Applicationpreview from '../job/comp/applicationpreview'
import { excludeItems } from '../../util'
import Preloader from '../../components/comps/preloader'
import NoDataCard from '../../components/comps/nodata'
const { Panel } = Collapse
export default function Application() {
    const [data, setdata] = useState()
    const { getData, loading } = useGetPrivateData()
    async function getApplied(params) {
        await getData("applications", (d) => setdata(d))
    }
    useEffect(() => {
        getApplied()
    }, [])

    return (
        <div class="col-12 col-xl-10 col-lg-10 mt-14 ">
            <Preloader open={loading || !data} />
            <div class="mb-5">
                <h4 class="font-size-7 mb-9">My Applications</h4>
                <div class="row justify-content-center py-3 px-3">
                    {data && data?.length > 0 ? data?.map((item, index) =>
                        <Collapse size='small' className='my-4 p-0 w-100' accordion>
                            <Panel key={index} className='rounded  bg-white' header={<div class="media mt-4 align-items-center">
                                <div class="square-52 bg-indigo mr-8 rounded">
                                    <img src={item.logo} alt="" className='img-fluid' />
                                </div>
                                <div>
                                    <h3 class="font-size-1 mb-0">
                                        <p class="heading-default-color font-weight-semibold">{item.title}</p>
                                    </h3><p class="font-size-3 text-default-color line-height-2"
                                    >{item.company}</p>
                                </div>
                            </div>}>
                                {<Applicationpreview data={excludeItems(item, ["jobId", "userId", "_id", "logo", "title", "company", "date"])} title={item.title} />}
                            </Panel>
                        </Collapse>) : data && <NoDataCard />}
                </div>
            </div>
        </div>
    )
}
