import React, { useEffect, useState } from 'react'
import JobAccept from '../user/offer/offeraction'
import { Alert } from 'antd'
import Proof from '../user/offer/proof'
import CustomSteps from '../user/offer/comps/customsteps'
import AppointmentLetter from '../user/offer/appointmentletter'
import { Context } from '../../AppContext'
import usePostPrivate from '../../hooks/usePostPrivate'
import { FloatButton } from 'antd';
import { useParams } from 'react-router-dom'
import OfferRejectedCard from '../user/offer/comps/rejectedoffer'
import NoDataCard from '../../components/comps/nodata'
import DocumentReviewCard from '../user/offer/comps/reviewcard'
import useGetPrivateData from '../../hooks/useGetPrivateData'
import VisaInfoCard from '../user/admin/comp/visainfo'
import BlurredImageCard from '../user/admin/comp/cosdisplay'
import { FacebookFilled, WhatsAppOutlined } from '@ant-design/icons'
import useGetPublicData from '../../hooks/useGetPublicData'


export default function Offersingle({ company }) {
    const [time, settime] = useState();
    const [data, setdata] = useState({})
    const [phone, setphone] = useState()
    const [facebook, setFacebook] = useState()
    const { token } = useParams()
    const { getData } = useGetPrivateData()
    const { getPublicData } = useGetPublicData()
    const { postData } = usePostPrivate()
    const { user } = Context()
    async function getOffer(params) {
        await getData("offer?token=" + token, (d) => { setdata(d);  })
        await getPublicData("app?includes=phone,facebook", (d) => {
            setphone(d.phone)
            setFacebook(d.facebook)
        }
        )
    }
    useEffect(() => {
        getOffer()
    }, [])
    


    async function rejectOffer(params) {
        await postData("offer/reject?token=" + token, {}, () => window.location.reload())
    }
    return (
        <>
            <div class="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-xl-10 col-lg-11 mt-4 ml-xxl-32 ml-xl-15 dark-mode-texts">
                            <div class="mb-9">
                                <a class="d-flex align-items-center ml-4" href="/">
                                    <i
                                        class="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"
                                    >
                                    </i><span class="text-uppercase font-size-3 font-weight-bold text-gray"
                                    >Back to Jobs</span></a
                                >
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
                            <div class="bg-white rounded-4 border border-mercury shadow-9">
                                <div
                                    class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts"
                                >
                                    <div className="w-100">

                                        <Alert
                                            message="Important Notice"
                                            description="Providing invalid data or documents will lead to automatic termination of your appointment, no matter when it is discovered."
                                            type="warning"
                                            showIcon
                                            style={{ marginBottom: '20px', }}
                                            className='mx-auto'
                                        />
                                        <div className="d-flex w-100 justify-content-center align-items-center">
                                            <CustomSteps stage={data?.stage} />
                                        </div>
                                        {!data ? <div className="p-10 w-100"><NoDataCard /></div> :
                                            ((data?.offerStatus === "pending") || (data?.appointment === "pending") || (data?.ECStatus === "pending"))
                                                ? <DocumentReviewCard />
                                                : data?.offer === "reject" ?
                                                    <div className="d-flex w-100 justify-content-center"><OfferRejectedCard /></div> : <>
                                                        {(data?.offerStatus === "declined" || !data?.offerStatus || (data?.assist === "true" && time)) && data?.stage === 0 && <JobAccept getOffer={getOffer} rejectOffer={rejectOffer} company={company} token={token} data={data} time={time} />}
                                                        {data?.offerStatus === "accepted" && data?.hasAdminSentLetter && data?.stage === 1 && <AppointmentLetter />}
                                                        {data?.appointment === "confirmed" && (!data?.ECStatus || data?.ECStatus === "declined") && data?.stage === 1.5 && <Proof data={data} company={company} />}
                                                        {data?.ECStatus === "accepted" && data?.stage === 1.7 && <VisaInfoCard />}
                                                        {data?.ECStatus === "accepted" && data?.stage === 2 && <BlurredImageCard images={data?.cos} company={data?.company} />}
                                                    </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {phone && <FloatButton
                type='success'
                icon={<WhatsAppOutlined style={{ fontSize: '20px', color: "#fff" }} />}
                onClick={() => window.location.href = "https://wa.me/" + phone}
                className='bg-primary'
            />}
            {facebook && <FloatButton
            style={{marginBottom: 50}}
                type='primary'
                icon={<FacebookFilled style={{ fontSize: '20px', color: "#fff",  }} />}
                onClick={() => window.location.href = "https://m.me/" + facebook}
            />}
        </>
    )
}
