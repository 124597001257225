import React, { useState } from 'react'
import AlertComp from '../../components/comps/alert'
import usePost from '../../hooks/usePost';

export default function ForgotPassword() {
    const [email, setemail] = useState('')
    const { loading, postData } = usePost()
    async function sendEmail(params) {
        await postData("forgot-password", { email })
    }
    return (
        <div className=" row h-100 px-8 pt-12 justify-content-center flex-column align-items-center">
            <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 my-9 my-lg-15 px-6  pt-10 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <div class="mt-5">
                    <h5 className='text-primary text-center'>Forgotten password?</h5>
                    <form className='mt-10' onSubmit={(e) => { e.preventDefault(); sendEmail() }}>
                        <AlertComp />
                        <div class="form-group">
                            <label
                                for="email"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >E-mail
                            </label>
                            <input
                                type="email"
                                class="form-control"
                                placeholder="example@gmail.com"
                                onChange={(e) => setemail(e.target.value)}
                                value={email}
                                required
                                aria-label="email"
                                name='email'
                            />
                        </div>
                        <div class="form-group mb-8">
                            <button
                                class="btn btn-primary btn-medium w-100 rounded-5"
                            >
                                {loading ? "Sending..." : "SEND"}
                            </button>
                        </div>
                        <a style={{ fontSize: 16 }}
                            href="/auth/login"
                            class="text-left w-100 mb-8"
                        >Go back to login</a>
                    </form>
                </div>
            </div></div>
    )
}
