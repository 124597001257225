import React from 'react'
import PaymentForm from '../comp/paymentform';
import AlertComp from '../../../components/comps/alert';

export default function Payment() {
    
    return (
        <div class="col-12 col-xl-10 col-lg-10 mt-14 ">
            <div class="mb-5">
                <h4 class="font-size-7 mb-9">Payment </h4>
                <div class="border-0 card py-10 px-6">
                    <AlertComp />
                    <PaymentForm />
                </div>
            </div>
        </div>
    )
}
