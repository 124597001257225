import React from 'react'
import { Context } from '../../../AppContext'

export default function UserInfo() {
    const { user } = Context()
    const { name, email, countryName, phone } = user
    return (
        <div class="col-12 col-md-10 mb-11 mb-lg-0">
            <div class="mr-0 mr-xl-17">
                <div class="pl-lg-5">
                    <div class="bg-white shadow-9 rounded-4">
                        <div class="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
                            <h5 class="text-black-2 mb-8 font-size-5">Contact Info</h5>
                            <div class="mb-7">
                                <p class="font-size-4 mb-0">User</p>
                                <h5
                                    class="font-size-4 font-weight-semibold mb-0 text-black-2 text-break"
                                >
                                    {name}
                                </h5>
                            </div>
                            <div class="mb-7">
                                <p class="font-size-4 mb-0">Location</p>
                                <h5
                                    class="font-size-4 font-weight-semibold mb-0 text-black-2 text-break"
                                >
                                    {countryName}
                                </h5>
                            </div>
                            <div class="mb-7">
                                <p class="font-size-4 mb-0">E-mail</p>
                                <h5 class="font-size-4 font-weight-semibold mb-0">
                                    <a class="text-black-2 text-break" href={"mailto:" + email}
                                    >{email}</a
                                    >
                                </h5>
                            </div>
                            <div class="mb-7">
                                <p class="font-size-4 mb-0">Phone</p>
                                <h5 class="font-size-4 font-weight-semibold mb-0">
                                    <a class="text-black-2 text-break" href={"tel:" + phone}
                                    >{phone}</a
                                    >
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
