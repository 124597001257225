import React from 'react';
import { Card, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const OfferRejectedCard = () => (
    <Card
        bordered={false}
        style={{
            width: 400,
            textAlign: 'center',
            backgroundColor: '#fff1f0',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            padding: '20px',
        }}
        className='my-15'
    >
        <CloseCircleOutlined style={{ fontSize: '48px', color: '#f5222d' }} />
        <Title level={3} style={{ marginTop: '20px', color: '#f5222d' }}>
            Offer Rejected
        </Title>
        <Text style={{ fontSize: '16px', color: '#595959' }}>
            This offer has been rejected, and the job has been terminated.
        </Text>
    </Card>
);

export default OfferRejectedCard;
