import React, { useState } from 'react';

const CustomSteps = ({ stage }) => {

  const steps = [
    'Offer',
    'Appointment Letter',
    'VISA',
  ];

  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="stepper-container justify-content-center">
        <div className="d-flex">{steps.map((step, index) => (
          <div key={index} className="step-wrapper">
            <div className="step-item">
              <div
                className={`step-circle ${stage === index ? 'active' : ''} ${stage > index ? 'completed' : ''
                  }`}

              >
                {index + 1}
              </div>
              <div className="step-label">{step}</div>
            </div>
            {index < steps.length - 1 && (
              <div className={`step-line ${stage > index ? 'completed-line' : ''}`}></div>
            )}
          </div>
        ))}</div>
      </div></div>
  );
};

export default CustomSteps;
