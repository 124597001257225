import React, { useEffect, useState } from 'react'
import AlertComp from '../../components/comps/alert';
import usePostPrivate from '../../hooks/usePostPrivate';
import { Context } from '../../AppContext';
import Preloader from '../../components/comps/preloader';
import NoDataCard from '../../components/comps/nodata';

export default function Settings() {
    const { user, setUser } = Context()
    const [password, setpassword] = useState("")
    const [name, setname] = useState()
    const [phone, setphone] = useState()
    const [retrypassword, setretrypassword] = useState("")
    const [isPassword, setIsPassword] = useState(false)
    const { postData, loading } = usePostPrivate()
    async function reset(params) {
        setIsPassword(true)
        await postData("reset-password/auth", { confirmPassword: retrypassword, password })
    }
    useEffect(() => {
        if (user?.name && user?.phone) {
            setname(user?.name)
            setphone(user?.phone)
        }
    }, [user])

    async function edit(params) {
        setIsPassword(false)
        await postData("user/update", { name, phone }, () => setUser({ ...user, name, phone }))
    }
    return (
        <>

            < div className="mt-14 d-flex justify-content-center col-12 pb-10" >
                <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 col-md-8  my-9 my-lg-15 px-6 pb-10 pt-10">
                    <div class="mt-5">
                        <h5 className='text-primary text-center'>Reset password</h5>
                        <form className='mt-10' onSubmit={(e) => { e.preventDefault(); reset() }}>
                            {isPassword && <AlertComp />}
                            <div class="form-group">
                                <label
                                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >Password
                                </label>
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder=""
                                    onChange={(e) => setpassword(e.target.value)}
                                    value={password}
                                    required
                                    aria-label="password"
                                    name='password'
                                />
                            </div>
                            <div class="form-group">
                                <label
                                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >Re-enter Password
                                </label>
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder=""
                                    onChange={(e) => setretrypassword(e.target.value)}
                                    value={retrypassword}
                                    required
                                    aria-label="password"
                                    name='retypepassword'
                                />
                            </div>
                            <div class="form-group mb-8">
                                <button
                                    class="btn btn-primary btn-medium rounded-5"
                                >
                                    {loading ? "processing..." : "RESET"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div></div >
            < div className="mt-14 d-flex justify-content-center col-12 pb-10" >
                <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 col-md-8  my-9 my-lg-15 px-6 pb-10 pt-10">
                    <div class="mt-5">
                        <h5 className='text-primary text-center'>Edit User Info</h5>
                        <form className='mt-10' onSubmit={(e) => { e.preventDefault(); edit() }}>
                            {!isPassword && <AlertComp />}
                            <div class="form-group">
                                <label
                                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >Name
                                </label>
                                <input
                                    type="name"
                                    class="form-control"
                                    placeholder=""
                                    onChange={(e) => setname(e.target.value)}
                                    value={name}
                                    aria-label="name"
                                    name='name'
                                />
                            </div>
                            <div class="form-group">
                                <label
                                    class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >Phone Number
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    onChange={(e) => setphone(e.target.value)}
                                    value={phone}
                                    aria-label="phone"
                                    name='phone'
                                />
                            </div>
                            <div class="form-group mb-8">
                                <button
                                    class="btn btn-primary btn-medium rounded-5"
                                >
                                    {loading ? "processing..." : "UPDATE"}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div >
        </>
    )
}
