import React from "react";
import { Card, Row, Col } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

const VisaInfoCard = () => {
    return (
        <div className="w-100 d-flex justify-content-center"><Card
            title="Visa & Certificate of Sponsorship Info"
            style={{ width: 400, margin: '50px auto', textAlign: 'center', borderRadius: 10, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
        >
            <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                <CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
                Information about visa processing and issuing of Certificate of Sponsorship
                will be communicated to you in the process of time.
            </p>
            <InfoCircleOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
        </Card>
        </div>
    );
};

export default VisaInfoCard;
