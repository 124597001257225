import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Context } from './../../../AppContext';
import { menu } from '../lib';
import { LogOut } from '../../../util';

export default function Sidebar() {
    const location = useLocation().pathname
    const { user } = Context()
    const { isAdmin, ready } = user

    function Menu(params) {
        if (user && isAdmin) {
            if (ready) {
                return menu
            } else {
                return menu.filter(item => item.name !== "Payment" && item.name !== "Payment History")
            }
        } else {
            const userMenu = menu.filter(item => item.name !== "Admin")
            if (ready) {
                return userMenu
            } else {
                return userMenu.filter(item => item.name !== "Payment" && item.name !== "Payment History")
            }
        }
    }

    return (
        <>  <div class="dashboard-sidebar-wrapper pt-11" id="sidebar">
            <ul class=" my-15 list-unstyled dashboard-layout-sidebar">
                {Menu().
                    map(item => <li key={item.name} class={location === item.link ? "active" : ""}>
                        <a
                            href={item.link}
                            onClick={(e) => { e.preventDefault(); item.name === "Log Out" ? LogOut() : window.location.href = item.link }}
                            class="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
                        >{item.icon}{item.name}</a>
                    </li>)}
            </ul>
        </div > <a class="sidebar-mobile-button" data-toggle="collapse" href="#sidebar" role="button" aria-expanded="false" aria-controls="sidebar">
                <i class="icon icon-sidebar-2"></i>
            </a></>
    )
}
