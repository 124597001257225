import Cookies from 'js-cookie';

export const domain = "https://job-backend-five.vercel.app/"
// export const domain = "http://localhost:5000/"
export function excludeItems(obj, excludeList) {
    const result = {};
    for (const key in obj) {
        if (!excludeList.includes(key)) {
            result[key] = obj[key];
        }
    }
    return result;
}
export function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function truncateEmail(email) {
    const atIndex = email.indexOf("@");
    if (atIndex > 6) {
        const username = email.substring(0, 2);
        const domain = email.substring(atIndex - 1);
        return `${username}...${domain}`;
    }
    return email;
}
export function randomDaysOrWeeks() {
    const number = Math.floor(Math.random() * 14) + 1;
    if (number < 7) {
        return `${number} day${number === 1 ? '' : 's'} ago`;
    } else {
        const weeks = Math.floor(number / 7);
        return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    }
}
export function objectCondition(params) {
    return Object.keys(params).length > 0
}
export function LogOut(params) {
    Cookies.remove("user")
    window.location.href = "/"
}
export function formatTimeRemaining(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const secs = seconds % 60;

    const pluralize = (unit, value) => value === 1 ? unit.slice(0, -1) : unit;

    const dayStr = days ? `${days} ${pluralize('days', days)}` : '';
    const hourStr = hours ? `${hours} ${pluralize('hours', hours)}` : '';
    const minuteStr = minutes ? `${minutes} ${pluralize('minutes', minutes)}` : '';
    const secStr = secs ? `${secs} ${pluralize('seconds', secs)}` : '';

    const timeArr = [dayStr, hourStr, minuteStr, secStr].filter(Boolean);

    return timeArr.join(' ');
}

export function stringToArray(str) {
    // Split the string by comma and trim whitespace from each element
    return str.split(',').map(item => item.trim());
}
export function generateRefNumber(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let refNumber = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        refNumber += characters.charAt(randomIndex);
    }
    return refNumber;
}