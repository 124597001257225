import React, { useEffect, useState } from 'react'
import { Typography } from 'antd'
import AlertComp from '../../../components/comps/alert'
const {  Text } = Typography;

export default function Applicationpreview({ data, title }) {
    function capitalizeString(str) {
        return str ? str
            .toLowerCase() // Convert the entire string to lowercase first
            .split(' ') // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ') : "N/A"
    }
    return (
        <div class="col-12 mb-8 px-2">
            <div class="rounded-4 ">
                <div
                    class="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts"
                >
                    <AlertComp />
                    <div class="row">
                        <div class="col-12">
                            <div class="media align-items-center">

                                <div>
                                    {title && <h3 class="font-size-6 mb-0 text-center"> Application for {title}</h3>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts"
                >

                    {Object.keys(data).map((item, index) => <p style={{ color: "#000" }} key={index}>
                        <Text >{item}:{" "}<Text strong>{capitalizeString(data[item])}</Text></Text>
                    </p>)}
                </div>
            </div>
        </div>
    )
}
