import React, { useEffect, useState } from 'react'
import { menuItems } from '../../lib'
import { useLocation } from 'react-router-dom'
import ProgressComp from '../comps/progress'
import { Context } from '../../AppContext'
import UserMenu from './usermenu'
import usePost from '../../hooks/usePost'
export default function Header({ logo, country, user }) {
    const location = useLocation()
    const { postData } = usePost()
    const { screenWidth } = Context()
    async function handlePostPath(params) {
        const data = { email: user?.email, location: location.pathname, country }
        await postData("location", { ...data })
    }
    const isAuth = useLocation().pathname.includes("auth")
    const authMenu = [{
        name: "Log In",
        link: "/auth/login"
    },
    {
        name: "Create Account",
        link: "/auth/register"
    },]

    useEffect(() => {
        if (user && !user?.isAdmin && !location.pathname.includes("admin") && country) {
            handlePostPath()
        }

    }, [location, user])
    const notUser = user && Object.keys(user)?.length === 0
    const Menu = screenWidth < 497 && notUser ? [...menuItems, ...authMenu] : menuItems
    return (
        <header class="site-header mb-5 site-header--menu-right bg-default py-7 py-lg-0 site-header--absolute site-header--sticky">
            <div class="container">
                <nav class="navbar site-navbar offcanvas-active navbar-expand-lg  px-0 py-0">
                    <div class="brand-logo">
                        <a href="/">
                            <img width={150} src={logo} alt="" class="light-version-logo default-logo" />
                        </a>
                    </div>
                    <div class="collapse navbar-collapse" id="mobile-menu">
                        <div class="navbar-nav-wrapper">
                            <ul class="navbar-nav main-menu">
                                {Menu.map(item => < li key={item.name} class="nav-item">
                                    <a class="nav-link" href={item.link}>{item.name}</a>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                    {((!isAuth && notUser) || notUser) && <div class="header-btns header-btn-devider ml-auto pr-2 ml-lg-6 d-none d-xs-flex">
                        <a href="/auth/login" class="btn btn-transparent text-uppercase font-size-3 heading-default-color focus-reset">
                            Log in
                        </a>
                        <a class="btn btn-primary text-uppercase font-size-3" href="/auth/register">
                            Sign up
                        </a>
                    </div>}
                    <div className="d-flex align-items-center">
                        {user?.name && !isAuth && <UserMenu name={user?.name[0]?.toUpperCase()} />}
                        {/* <!-- Mobile Menu Hamburger--> */}
                        <button class="navbar-toggler btn-close-off-canvas  hamburger-icon border-0" type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="false" aria-label="Toggle navigation">
                            {/* <i class="icon icon-simple-remove icon-close"></i> */}
                            <span class="hamburger hamburger--squeeze js-hamburger">
                                <span class="hamburger-box">
                                    <span class="hamburger-inner"></span>
                                </span>
                            </span>
                        </button>
                        {/* <!--/.Mobile Menu Hamburger Ends--> */}</div>
                </nav>
            </div>
            <ProgressComp />
        </header>
    )
}
