import React from 'react'
import { useLocation } from 'react-router-dom'

export default function Footer({ logo, company }) {
    const location = useLocation().pathname.includes("user")
    return (
      !location &&   <footer class="footer bg-ebony-clay dark-mode-texts">
            <div class="container text-center">
                <div class="pt-10 pt-lg-10 pb-13 pb-lg-10 border-bottom border-width-1 border-default-color-2">
                    <a href="/">
                        {<img src={logo} alt="" class="img-fluid" />}
                    </a>
                    <p style={{ fontSize: 12 }} class="my-5">&copy; <span id="currentYear">{new Date().getFullYear()}</span> {company}. All rights reserved.</p></div>

            </div>
        </footer>

    )
}
