import { Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import AlertComp from '../../components/comps/alert';
import useGetPrivateData from '../../hooks/useGetPrivateData';
import { useParams, useSearchParams } from 'react-router-dom';
import usePostPrivate from '../../hooks/usePostPrivate';
import Error404 from '../error/error404';
import { Context } from '../../AppContext';

export default function Interview() {
    const [userData, setUserData] = useState();
    const [questions, setQuestions] = useState({});
    const [status, setstatus] = useState();
    const [email, setEmail] = useState("");
    const [isPassword, setIsPassword] = useState(false);
    const [msg, setmsg] = useState();
    const [time, settime] = useState();
    const [index, setIndex] = useState(0);
    const [isFetched, setIsfetched] = useState(false);
    const [questLength, setQuestLength] = useState(0);
    const [answers, setAnswers] = useState([]);
    const { id } = useParams()
    const { getData } = useGetPrivateData()
    const { postData } = usePostPrivate()
    const start = useSearchParams()[0].get("start")
    const { user } = Context()
    function objectCondition(params) {
        if (params) {
            return Object.keys(params).length > 0
        }
        return false
    }
    async function interview(params) {
        await getData("interview?token=" + id, async (d) => {
            setUserData(d)
            setEmail(d.email)
            setIsPassword(d.isPassword)
        }
        )
    }
    async function interviewQuestions(started = false) {
        await getData("interview/questions?token=" + id + "&started=" + (started ? "true" : "") + "&index=" + index, (d) => {
            setQuestions(d.data);
            settime(d.time); setmsg(d.msg);
            setstatus(d.status);
            setQuestLength(d.data.questionLength);
            setIsfetched(!isFetched)
        })
    }
    async function answer(params) {
        await postData("interview/submit?token=" + id, { answers }, async () => { await interviewQuestions() })
    }
    useEffect(() => {
        interview()
    }, [])
    useEffect(() => {
        let isCancelled = false;
        function delay() {
            return new Promise(resolve => setTimeout(resolve, 1000));
        }
        async function fetchWhenZero(t) {
            for (let i = 0; i < t; i++) {
                if (isCancelled) return;
                settime(i === t - 1 ? undefined : (pt) => pt - 1)
                await delay()
            }
            if (!isCancelled && t) {
                await interviewQuestions();
            }
        }
        fetchWhenZero(time)

        return () => {
            isCancelled = true; // Cleanup the effect
        };
    }, [isFetched])
    useEffect(() => {
        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'visible') {
                // Function to run when the user comes back to the tab
               await interviewQuestions()
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (objectCondition(userData)) {
            interviewQuestions()
        }
    }, [index, userData])
    useEffect(() => {
        if (msg && start && status === "not_started" && isPassword) {
            interviewQuestions(true)
        }
    }, [msg, status, user,isPassword])

    const onChange = (e) => {
        if (answers.map(item => item.index).includes(index)) {
            setAnswers(prev => prev.map(item => {
                if (item.index === index) {
                    return {
                        ...item,
                        answer: e.target.value
                    }
                }
                return item
            }))
        } else {
            setAnswers([...answers, { index, answer: e.target.value, id: questions._id }])
        }
    };
    return (
        userData && objectCondition(userData) ? <div className=" row h-100 px-8 pt-15 justify-content-center flex-column align-items-center">
            <div className="pt-10 col-12 my-9 my-lg-15 px-6 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <p style={{ color: "#000", textAlign: "center" }}><span style={{ color: "#777" }}>Candidate's Name:</span> {userData?.firstName} {userData?.lastName} {userData?.middleName || ""}</p>
                <p style={{ color: "#000", textAlign: "center" }}><span style={{ color: "#777" }}>Job Title:</span> {userData?.title}</p>
            </div>
            <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 my-9 my-lg-5 px-6  pt-4 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <div class="mt-5 w-100">
                    <AlertComp />
                    {msg ? <><h6 dangerouslySetInnerHTML={{ __html: msg }} className='text-center my-10' />
                        {time && typeof (time) === "number" && time !== undefined && <h2 className='text-center my-10'>{time} sec(s)</h2>}
                        {status === "not_started" && !start && <button
                            class="btn btn-primary btn-medium w-100 rounded-5 my-8"
                            onClick={() => {
                                isPassword ?
                                    user?.name ? interviewQuestions(true) :
                                        window.location.href = "/auth/login?email=" + email + "&page=/interview/" + id : window.location.href = "/auth/reset-password?email=" + email + "&page=/interview/" + id
                            }}

                        >
                            Start interview
                        </button>}
                    </> :
                        Object.keys(questions).length !== 0 && <form className='mt-10' onSubmit={(e) => { e.preventDefault() }}>
                            <h4 className='text-center text-danger my-10'>Do not refresh the page or you'll lose your answers and start afresh.</h4>
                            <h6 className='text-primary text-center w-100'>
                                <span style={{ color: "#000" }}>Question {index + 1}:</span> {questions?.question}</h6>
                            {time && typeof (time) === "number" && time !== undefined && <h2 className='text-center my-10'>{time} sec(s)</h2>}
                            <Radio.Group size="large" onChange={onChange} value={answers[index]?.answer}>
                                <Space direction='vertical'>
                                    {(questions?.options)?.map((item, index) =>
                                        <Radio key={index} className='form-group font-size-4' value={item}>{item}</Radio>
                                    )}
                                </Space>
                            </Radio.Group>
                            <p style={{ fontSize: 12 }} className='text-danger text-center my-8'>Choose the correct answer and go to the next question before the time runs out.
                                You have <strong className='text-dark'>{questLength - (index + 1)}</strong> question(s) remaining.</p>
                            <div className='d-flex w-100 justify-content-between'>
                                <button disabled={index === 0}
                                    onClick={() => setIndex(index - 1)} className='btn btn-outline-mercury text-black-2 text-uppercase  rounded-3 mb-5 px-5'>Prev</button>
                                <button disabled={index === questLength - 1 || !answers.map(item => item.index).includes(index)}
                                    onClick={() => { setIndex(index + 1) }} className='btn btn-outline-mercury text-black-2 text-uppercase  rounded-3 mb-5 px-5'>Next</button>
                            </div>
                        </form>}
                    {status === "active" && <div className='my-8'>
                        {objectCondition(questions) && <p style={{ fontSize: 12 }} className='text-danger text-center'>Make sure you've answered all the <strong className='text-dark'>{questLength}</strong>{" "}
                            questions before submitting.</p>}
                        <button
                            class="btn btn-primary btn-medium w-100 rounded-5"
                            onClick={answer}
                        >
                            Submit
                        </button></div>}
                </div>
            </div>
        </div> : userData && <Error404 />
    )
}
