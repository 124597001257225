import React from 'react';
import { Card, Empty } from 'antd';
import { FrownOutlined } from '@ant-design/icons';

const { Meta } = Card;

const NoDataCard = ({ isBorder = true }) => (
    <Card
        style={{ width: "100%", textAlign: 'center' }}
        cover={<FrownOutlined style={{ fontSize: '48px', color: '#ccc' }} />}
        className='py-10'
        bordered={isBorder}
    >
        <Meta
            title="No Data Found"
            description={<Empty description={false} />}
        />
    </Card>
);

export default NoDataCard;
