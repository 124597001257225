import React from 'react'
import CryptoPaymentHistory from './comp/paymenthistory'

export default function History() {
    return (
        <div class="col-12 col-xl-10 col-lg-10 mt-14 ">
            <div class="mb-5 px-5">
                <h4 class="font-size-7 mb-9">Payment History</h4>
                <div class="row justify-content-center card py-3 px-3">
                    <CryptoPaymentHistory />
                </div>
            </div>
        </div>
    )
}
