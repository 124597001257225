import React, { useEffect, useState } from 'react'
import AlertComp from '../../components/comps/alert'
import usePost from '../../hooks/usePost'
import { useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie';

export default function Login() {
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [isChecked, setisChecked] = useState(true)
    const { postData, loading } = usePost()
    const { postData: verify, } = usePost()
    const token = useSearchParams()[0].get("token")
    const urlEmail = useSearchParams()[0].get("email")
    const page = useSearchParams()[0].get("page")
    async function login(params) {
        await postData("login?page=" + page, { email, password }, (d) => {
            if (isChecked) {
                Cookies.set("user", d, { expires: 30 });
            } else {
                Cookies.set("user", d);
            }
            window.location.href = page ? page + "?start=started" : "/"
        })
    }
    async function verifyToken(params) {
        await verify("verify-email", { token })
        window.history.replaceState(null, '', '/auth/login')
    }
    useEffect(() => {
        if (token) {
            verifyToken()
        }
    }, [token])
    useEffect(() => {
        if (urlEmail) {
            setemail(urlEmail)
        }
    }, [])

    return (
        <div className=" row h-100 px-8 pt-12 justify-content-center flex-column align-items-center">
            <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 my-9 my-lg-15 px-6  pt-10 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <div class="mt-5">
                    <h5 className='text-primary text-center'>Sign In</h5>
                    <form className='mt-10' onSubmit={(e) => { e.preventDefault(); login() }}>
                        <AlertComp />
                        <div class="form-group">
                            <label
                                for="email"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >E-mail
                            </label>
                            <input
                                type="email"
                                class="form-control"
                                placeholder="example@gmail.com"
                                onChange={(e) => setemail(e.target.value)}
                                value={email}
                                required
                                aria-label="email"
                                name='email'
                            />
                        </div>
                        <div class="form-group">
                            <div class="d-flex flex-wrap justify-content-between"><label
                                for="password"
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >Password</label>
                                <a href="/auth/forgot-password" class="font-size-3 text-primary line-height-reset"
                                >Forgotten Password?</a>
                            </div>

                            <div class="position-relative">
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter password"
                                    onChange={(e) => setpassword(e.target.value)}
                                    value={password}
                                    required
                                />
                            </div>
                        </div>
                        <div class="form-group d-flex flex-wrap justify-content-between">
                            <label for="terms-check" class="gr-check-input d-flex mr-3">
                                <input class="d-none" type="checkbox" checked={isChecked} onChange={() => setisChecked(!isChecked)} />
                                <span class="checkbox mr-5"></span>
                                <span class="font-size-3 mb-0 line-height-reset mb-1 d-block"
                                >Keep me logged in</span
                                >
                            </label>

                        </div>
                        <p className='text-left ml-0 p-0' style={{ fontSize: 14 }}>By signing in, you are acknowledging our <a
                            href="/privacy"
                            class="text-primary"
                        >privacy notice </a>.</p>
                        <div class="form-group mb-8">
                            <button
                                class="btn btn-primary btn-medium w-100 rounded-5"
                            >
                                {loading ? "Signing in..." : "SIGN IN"}
                            </button>
                        </div>
                        <p style={{ fontSize: 14 }} class="heading-default-color text-center">
                            Don’t have an account?{" "}
                            <a href="/auth/register" class="text-primary"> Create an account</a>
                        </p>
                    </form>
                </div>
            </div></div>

    )
}
