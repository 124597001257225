import React, { useEffect, useState } from 'react';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import { Modal } from 'antd';
import usePostPrivate from '../../../hooks/usePostPrivate';
import AlertComp from '../../../components/comps/alert';
import { truncateEmail } from '../../../util';
import NoDataCard from '../../../components/comps/nodata';

const InterviewResult = () => {
    const { getData } = useGetPrivateData()
    const [data, setdata] = useState([])
    const [open, setopen] = useState(false)
    const [active, setactive] = useState()
    async function fetchJobs(params) {
        await getData("admin/interview", (d) => { setdata(d); })
    }
    const { postData } = usePostPrivate()
    async function updateStatus(status) {

        await postData("admin/interview", { ...data[active], status },)
        setopen(false); setactive();
    }

    useEffect(() => {
        fetchJobs()
    }, [])

    useEffect(() => {
        const mod = document.getElementsByClassName("ant-modal-content")
        if (mod && open) {
            mod[0]?.classList.add("px-0")
        }
    }, [open])
    return (
        <>  <div class="col-12 col-xl-10 col-lg-10 mt-3 ">
            <div class="mb-5 px-5">
                <h6 className='my-3 text-center'>Interview </h6>
                <AlertComp />
                <div class="row justify-content-center card py-3 px-3">
                    {data && data?.length ? <div class="table-responsive">
                        <table class="table table-striped">
                            <tbody>
                                {data.map((item, index) =>
                                    <tr role='button' onClick={() => { setactive(index); setopen(true) }} class="border d-flex align-items-center justify-content-between border-color-2">
                                        <th scope="row" class="pl-6 border-0 py-7 pr-0">
                                            <span
                                                class="media align-items-center"
                                            >
                                                <h4
                                                    class="font-size-4 mb-0 font-weight-semibold text-black-2"
                                                >
                                                    {truncateEmail(item.email)}
                                                </h4>
                                            </span>
                                        </th>
                                        <td class="table-y-middle py-7 pr-0">
                                            <div class="d-flex justify-content-between">
                                                <span
                                                    role='button'
                                                    class="font-size-3 font-weight-bold btn btn-unstyled text-success text-uppercase">{item.result}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div> : data && <NoDataCard isBorder={false} />}
                </div>
            </div>
        </div>
            <Modal
                style={{ top: 20, }}
                open={open}
                onCancel={() => { setactive(); setopen(false); }}
                footer={() => null}
            >
                <div className='d-flex justify-content-between px-7 mt-15'>
                    <button onClick={() => updateStatus("reject")} className='btn btn-danger'>DECLINE</button>
                    <button onClick={() => updateStatus("accept")} className='btn btn-success'>ACCEPT</button>
                </div>
            </Modal>
        </>
    );
};

export default InterviewResult;
