import React, { useEffect, useState } from 'react';
import { Form, Input, Upload, Button, Radio, Typography, Alert, Row, Col } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import LicenseInfoModal from './comps/infomodal';
import { useParams } from 'react-router-dom';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import usePostFormPrivate from '../../../hooks/usePostForm';
import { Context } from '../../../AppContext';
import AlertComp from '../../../components/comps/alert';
import usePostPrivate from '../../../hooks/usePostPrivate';
const { Text, Link } = Typography;

const AppointmentLetter = ({ company }) => {
    const [appointment, setAppointment] = useState([]);

    const { token } = useParams()
    const { postFormData } = usePostFormPrivate()
    const { postData } = usePostPrivate()
    const { user } = Context()


    async function uploadAppointmentLetter(params) {
        const formData = new FormData();
        appointment.map(file => formData.append('appointment', file.originFileObj))
        formData.append('email', user?.email)
        formData.append('token', token);
        await postFormData("offer/update/appointment", formData, async () => { window.location.reload() })
    }
    async function rejectOffer(params) {
        await postData("offer/reject?token=" + token, {}, () => window.location.reload())
    }
    return (

        <Form
            layout="vertical"
            style={{ margin: '0 10' }}
            className='px-5 w-100 py-10'
        >
            <div className="my-5"><AlertComp /></div>
            <Form.Item label="Upload Signed Copy of the Appointment Letter">
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="appointment" valuePropName="fileList" getValueFromEvent={(e) => e.fileList} noStyle>
                            <Upload maxCount={4} fileList={appointment} onChange={(e) => { setAppointment(e.fileList) }} listType="picture-card" beforeUpload={() => false} accept="image/*">
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item>
                <button onClick={uploadAppointmentLetter}
                    className='btn btn-primary' disabled={appointment.length === 0} type="submit" style={{ backgroundColor: '#008a5b', borderColor: '#008a5b', marginRight: '8px' }}>
                    Upload letter
                </button>
                <button onClick={rejectOffer} className='btn btn-danger'>
                    Reject Offer
                </button>
            </Form.Item>

        </Form>

    );
};

export default AppointmentLetter;
