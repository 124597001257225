import React, { useEffect, useState } from 'react';
import { Card, Button, Typography, message, FloatButton } from 'antd';
import { CopyOutlined, FacebookFilled, WhatsAppOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import { useSearchParams } from 'react-router-dom';
import CryptoPaymentModal from './confirmPaymment';
import useGetPublicData from '../../../hooks/useGetPublicData';
import Preloader from '../../../components/comps/preloader';

const { Title, Text } = Typography;

const CryptoPaymentForm = () => {
    const amount = useSearchParams()[0].get("amount")
    const reason = useSearchParams()[0].get("reason")
    const [open, setopen] = useState(false)
    const [cryptoName, setcryptoName] = useState("")
    const [network, setnetwork] = useState("")
    const [walletAddress, setwalletAddress] = useState("")
    const [phone, setphone] = useState()
    const [facebook, setFacebook] = useState("")
    const { getPublicData, loading } = useGetPublicData()
    async function fetchApp(params) {
        await getPublicData("app?includes=cryptoName,network,walletAddress,phone,facebook", (d) => {
            const { cryptoName, network, walletAddress, phone, facebook } = d
            setcryptoName(cryptoName)
            setwalletAddress(walletAddress)
            setnetwork(network)
            setphone(phone)
            setFacebook(facebook)
        })
    }
    useEffect(() => {
        fetchApp()
    }, [])

    const handleCopyAddress = async () => {
        await navigator?.clipboard?.writeText(walletAddress);
        message.success('Wallet address copied to clipboard');
    };

    const handleProceed = () => {
        setopen(true)
    };
    useEffect(() => {
        if (!reason || !amount) {
            window.location.href = "/user/payment"
        }
    }, [amount, reason])

    return (
        <>
            <Preloader open={!cryptoName || !network || !walletAddress || loading} />
            <div className='text-center'>
                <Title level={2}>{cryptoName} Payment</Title>
                <Text type="secondary">Network: {network}</Text>
                <div style={{ margin: '20px 0' }}>
                    <QRCode value={walletAddress} size={200} />
                </div>
                <Text copyable={{ text: walletAddress }} style={{ fontSize: '16px' }}>
                    Wallet Address: {walletAddress}
                </Text>
                <Button
                    icon={<CopyOutlined />}
                    onClick={handleCopyAddress}
                    style={{ marginTop: 10, marginLeft: 10 }}
                >
                    Copy Wallet Address
                </Button>
                <div style={{ marginTop: 20 }}>
                    <Text type="danger">
                        Please ensure you pay <strong >{amount} {cryptoName}</strong> to {network} network, or you risk losing your funds.
                    </Text>
                </div>
                <Button className='btn btn-primary float-left w-25 ml-7' onClick={handleProceed} style={{ marginTop: 20 }}>
                    Complete Payment
                </Button>
            </div>
            <CryptoPaymentModal open={open} callBack={() => setopen(false)} amount={amount} reason={reason} />
            <p style={{ fontSize: 12, textAlign: "center", marginTop: 10 }}>Don't know how to deposit with crypto?
                Contact Us by clicking the contact button.</p>

            {phone && <FloatButton
                type='success'
                icon={<WhatsAppOutlined style={{ fontSize: '20px', color: "#fff" }} />}
                onClick={() => window.location.href = "https://wa.me/" + phone}
                className='bg-primary'
            />}
            {facebook && <FloatButton
                style={{ marginBottom: 50 }}
                type='primary'
                icon={<FacebookFilled style={{ fontSize: '20px', color: "#fff", }} />}
                onClick={() => window.location.href = "https://m.me/" + facebook}
            />}
        </>
    );
};

export default CryptoPaymentForm;
