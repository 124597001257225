import { Alert } from '@mui/material'
import React from 'react'
import { Context } from '../../AppContext'

export default function AlertComp() {
    const { feedback } = Context()
    return (
        feedback ? <Alert className='my-4' severity={feedback?.type} >
            <span dangerouslySetInnerHTML={{ __html: feedback?.msg }} style={{ fontSize: 14 }} />
        </Alert> : <></>
    )
}
