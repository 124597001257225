import React, { useState } from 'react'
import AlertComp from '../../components/comps/alert'
import usePost from '../../hooks/usePost';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';


export default function ResetPassword() {
    const [password, setpassword] = useState("")
    const [retypepassword, setretypepassword] = useState("")
    const token = useSearchParams()[0].get("token")
    const email = useSearchParams()[0].get("email")
    const page = useSearchParams()[0].get("page")
    const { loading, postData } = usePost()
    async function reset(params) {
        await postData("reset-password", { password, confirmPassword: retypepassword, token, email },
            async () => page ? await postData("login", { email, password }, (d) => {
                Cookies.set("user", d, { expires: 30 });
                window.location.href = page + "?start=started"
            }) : window.location.href = "/auth/login")
    }
    return (
        <div className=" row h-100 px-8 pt-12 justify-content-center flex-column align-items-center">
            <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 my-9 my-lg-15 px-6  pt-10 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                <div class="mt-5">
                    <h5 className='text-primary text-center'>{email ? "Create Password" : "Reset password"}</h5>
                    <form className='mt-10' onSubmit={(e) => { e.preventDefault(); reset() }}>
                        <AlertComp />
                        <div class="form-group">
                            <label
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >Password
                            </label>
                            <input
                                type="password"
                                class="form-control"
                                placeholder=""
                                onChange={(e) => setpassword(e.target.value)}
                                value={password}
                                required
                                aria-label="password"
                                name='password'
                            />
                        </div>
                        <div class="form-group">
                            <label
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >Re-enter Password
                            </label>
                            <input
                                type="password"
                                class="form-control"
                                placeholder=""
                                onChange={(e) => setretypepassword(e.target.value)}
                                value={retypepassword}
                                required
                                aria-label="password"
                                name='retypepassword'
                            />
                        </div>
                        <div class="form-group mb-8">
                            <button
                                class="btn btn-primary btn-medium w-100 rounded-5"
                            >
                                {loading ? "processing..." : email ? "CREATE" : "RESET"}
                            </button>
                        </div>
                        <a style={{ fontSize: 16 }}
                            href="/auth/login"
                            class="text-left w-100 mb-8"
                        >Go back to login</a>
                    </form>
                </div>
            </div></div>

    )
}
