export const menuItems = [
    {
        name: "Jobs",
        link: "/"
    },
    {
        name: "Contact",
        link: "/contact"
    },

]