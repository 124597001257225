import React, { useEffect, useState } from 'react'
import usePostPrivate from '../../../hooks/usePostPrivate'
import AlertComp from '../../../components/comps/alert'
import useGetPrivateData from '../../../hooks/useGetPrivateData'
import NoDataCard from '../../../components/comps/nodata'

export default function Adminsettings() {
    const { postData } = usePostPrivate()
    const { getData } = useGetPrivateData()
    const [data, setData] = useState()
    const [inputValue, setInputValue] = useState([])

    async function fetchAppData(params) {
        await getData("app", async (d) => {
            setData(Object.keys(d).filter(item =>
                !["activeSession", "_id", "id", ].includes(item))
                .map(item => {
                    return {
                        name: item,
                        value: d[item],
                        prevValue: d[item]
                    }
                }))

        })
    }
    function handleInputChange(i, value) {
        setData(data?.map((item, index) => {
            if (i === index) {
                return {
                    ...item,
                    value
                }
            }
            return item
        }))
    }
    useEffect(() => {
        data && data?.length > 0 && setInputValue(data?.map(item => item.value))
    }, [data])
    async function handleSaveData(e) {
        let updateData = {}
        const resData = data.filter(item => !inputValue.includes(item.prevValue))

        resData.forEach(item => {
            updateData[item.name] = item.value;
        });
        await postData("app", updateData, async () => { await fetchAppData() })
    }
    useEffect(() => {
        fetchAppData()
    }, [])


    return (
        <> < div className="mt-5 d-flex justify-content-center col-12 pb-10" >
            <div style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }} class="col-12 col-md-8  my-9 my-lg-15 px-6 pb-10 pt-10">
                <div class="mt-5">
                    <h5 className='text-primary text-center'>Edit Site</h5>
                    {data && data?.length > 0 ? <form className='mt-10' onSubmit={(e) => { e.preventDefault(); handleSaveData() }}>
                        {<AlertComp />}
                        {data?.map((item, index) => <div class="form-group">
                            <label
                                class="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                            >{item.name}
                            </label>
                            <input
                                class="form-control"
                                value={inputValue[index]}
                                onChange={(e) => { handleInputChange(index, e.target.value) }}
                            />
                        </div>)}

                        <div class="form-group mb-8">
                            <button
                                class="btn btn-primary btn-medium rounded-5"
                                type='submit'
                            >
                                Save
                            </button>
                        </div>
                    </form> : data && <NoDataCard />}
                </div>
            </div></div >
        </>
    )
}
