import React, { useEffect, useState } from 'react';
import { Collapse, Card, List, Typography } from 'antd';
import useGetPrivateData from '../../../../hooks/useGetPrivateData';
import { excludeItems, truncateEmail } from '../../../../util';
import usePostPrivate from '../../../../hooks/usePostPrivate';
import Preloader from '../../../../components/comps/preloader';
import NoDataCard from '../../../../components/comps/nodata';

const { Panel } = Collapse;
const { Title, Text } = Typography;

const UserDetails = () => {
    const [data, setdata] = useState()
    const { getData } = useGetPrivateData()
    const { postData } = usePostPrivate()

    async function fetchData(params) {
        await getData("admin/users", (d) => setdata(d))
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <Preloader open={!data} />
            {data && data?.length > 0 ? <Collapse size='large' className='my-4  border-0 p-0 w-100' accordion>
                {data.map((user, index) => (
                    <Panel
                        key={index}
                        header={
                            <div className='d-flex justify-content-between '>
                                <Text strong>{truncateEmail(user.email)}</Text>
                                <Text className='mx-5' strong>{user.countryName}</Text>
                            </div>
                        }
                    >
                        <Card bordered={false}>
                            <List>
                                {Object.keys(excludeItems(user, ["_id", "countryCode"])).map(item => <List.Item>
                                    <Text strong>{item}:</Text> {user[item]}
                                </List.Item>)}
                            </List>
                            <button onClick={() => postData("delete/user?id=" + user._id, {})} className='btn btn-danger ml-5 mt-10'>Delete</button>
                        </Card>
                    </Panel>
                ))}
            </Collapse> : data && <NoDataCard isBorder={false} />}
        </>
    );
};

export default UserDetails;
