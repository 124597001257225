import React, { useState } from 'react';
import { Form, Upload, Button, Typography, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import usePostFormPrivate from '../../../../hooks/usePostForm';
import AlertComp from '../../../../components/comps/alert';

const { Text, Link } = Typography;

const CosUpload = ({ id }) => {
    const [cos, setCos] = useState([]);
    const { postFormData } = usePostFormPrivate()


    async function uploadCert(params) {
        const formData = new FormData();
        cos.map(file => formData.append('cos', file.originFileObj))
        formData.append('applicationId', id);
        await postFormData("offer/update/cos", formData, async () => { window.location.reload(); })
    }

    return (

        <>
            <Form
                layout="vertical"
                style={{ margin: '0 10' }}
                className='px-5 w-100 py-10'
            >
                <div className="my-5"><AlertComp /></div>
                <Form.Item label={"Upload CoS"}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="cos" valuePropName="fileList" getValueFromEvent={(e) => e.fileList} noStyle>
                                <Upload maxCount={4} fileList={cos} onChange={(e) => { setCos(e.fileList) }} listType="picture" accept="image/*">
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <button onClick={() => uploadCert()}
                        disabled={cos.length === 0}
                        className='btn btn-primary'
                        type="submit"
                        style={{ backgroundColor: '#008a5b', borderColor: '#008a5b', marginRight: '8px' }}>
                        Upload COS
                    </button>
                </Form.Item>

            </Form>
        </>
    );
};

export default CosUpload;
